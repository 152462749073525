import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Category} from "../../model/category/category";
import {AppConstants} from "../../common/app-constants";
import {CategoryService} from "../../service/category.service";
import {ErrorDto} from "../../model/error/error-dto";


@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html'
})
export class CategoryListComponent implements OnInit, OnDestroy {

  categories: Category[] = [];
  destroy$ = new Subject();
  faTrash = AppConstants.iconTrash;
  faEdit = AppConstants.iconEdit;
  faEye = AppConstants.iconEye;
  faBan = AppConstants.iconBan;
  errorDto: ErrorDto = new ErrorDto();

  constructor(private categoryService: CategoryService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.getCategories();
  }

  private getCategories() {
    this.spinner.show().then(r => r);
    this.categoryService.getCategories(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categoriesResponse => {
          this.spinner.hide().then(r => r);
          this.categories = categoriesResponse.data;
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load categories failed', 'Category'));
        }
      });
  }

  public removeCategory(category: Category) {
    if (!confirm('Are you sure to delete category' + category.name + '?')) {
      return;
    }
    this.spinner.show().then(r => r);
    this.categoryService.deleteCategory(category, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.info('Category ' + category.url + ' deleted', 'Category');
              this.categories = this.categories.filter(data => data.url !== category.url);
            });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' delete failed', 'Category'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
