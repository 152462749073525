// auth.guard.ts

import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {TokenStorageService} from './service/token-storage.service';
import {AuthService} from './service/auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const tokenStorage = inject(TokenStorageService);

  console.log('Call auth guard');
  if (tokenStorage.isExpired()) {
    authService.logout();
    window.alert('You don\'t have permission to view this page');
    router.navigate(['/login']).then(r => r)
    return false;
  }
  return true;

};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => authGuard(route, state);
