import {Component, Input} from '@angular/core';
import {AddressDetailBaseDto} from "../../model/order/address-detail-base-dto";

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html'
})
export class AddressViewComponent {

  @Input()
  address: AddressDetailBaseDto = new AddressDetailBaseDto()

  @Input()
  type: string = 'Fakturacni'

}
