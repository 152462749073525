import {OrderItemResponseDto} from './order-item-response-dto';
import {AddressDetailBaseDto} from './address-detail-base-dto';


export class OrderDetailOutputDto {
  orderId: number;
  paymentType: PaymentType;
  note: string;
  priceAmount: number;
  priceCurrency: CurrencyType;
  status: OrderStatusType;
  owner: string;
  orderItems: OrderItemResponseDto[];
 // user?: UserResponseDto;
  billingAddress?: AddressDetailBaseDto;
  shippingAddress?: AddressDetailBaseDto;
}

export enum CurrencyType {
  CZK,
  EUR
}

enum OrderStatusType {
  CREATED,
  PROCESSING,
  SHIPPED,
  FINISHED
}

enum PaymentType{
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  CASH_ON_DELIVERY
}


