import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorDto} from "../model/error/error-dto";
import {Subject} from "rxjs";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {StatisticsService} from "../service/statistics.service";
import {takeUntil} from "rxjs/operators";
import {GraphPieVo} from "../model/stats/graph-pie-vo";
import {GraphLineVo} from "../model/stats/graph-line-vo";

@Component({
  selector: 'app-graph-home',
  templateUrl: './graph-home.component.html'
})
export class GraphHomeComponent implements OnInit, OnDestroy {

  errorDto: ErrorDto = new ErrorDto();
  destroy$ = new Subject();

  view: any[] = [700, 300];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  /**
   * This class serves as demo angular charts
   * TODO: Eshop overview
   */

  protected orderStatuses = []
  protected categoryToProductCount = []
  protected orderPriceTotalPerDay: Array<MultiVo> = []

  single = [
    {
      "name": "Users",
      "value": 50
    },
    {
      "name": "Orders",
      "value": 37000
    },
    {
      "name": "Categories",
      "value": 72
    },
    {
      "name": "Products",
      "value": 5000
    },
    {
      "name": "Messages",
      "value": 15
    },
    {
      "name": "Total revenue",
      "value": 100000
    },
  ];


  constructor(private statisticsService: StatisticsService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadGraphs()
  }

  private loadGraphs() {
    this.spinner.show().then(r => r);
    this.statisticsService.getOrderPieStatus(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: orderPieResponse => {
          this.spinner.hide().then(r => r);
          this.orderStatuses = orderPieResponse.map(responseItem => new GraphPieVo(responseItem.status, responseItem.total))
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load categories failed', 'Stats'));
        }
      });

    this.statisticsService.getCategoryToProductCount(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categoryProductCountResponse => {
          this.spinner.hide().then(r => r);
          this.categoryToProductCount = categoryProductCountResponse.map(responseItem => new GraphPieVo(responseItem.name, responseItem.total))
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load categories failed', 'Stats'));
        }
      })

    this.statisticsService.getOrderTotalPerDay(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: orderTotalPerDayResponse => {
          this.spinner.hide().then(r => r);
          let orderPriceTotalPerDay = orderTotalPerDayResponse.map(responseItem => new GraphLineVo(responseItem.status, responseItem.totalPrice));
          this.orderPriceTotalPerDay = [new MultiVo(orderPriceTotalPerDay)]
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load categories failed', 'Stats'));
        }
      })
  }

  onSelect(event) {
    console.log(event);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}


/*
multi = [
  {
    "name": "Germany",
    "series": [
      {
        "name": "30.1.2022",
        "value": 70000
      },
      {
        "name": "1.2.2022",
        "value": 10000
      },
      {
        "name": "2.2.2022",
        "value": 18000
      }
    ]
  },
];*/
export class MultiVo {
  name: string;
  series: GraphLineVo[];

  constructor(series: GraphLineVo[]) {
    this.name = "Default";
    this.series = series;
  }
}
