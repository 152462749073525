import {Directive} from '@angular/core';
import {Validator, AbstractControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[appIsbnValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: IsbnValidatorDirective,
    multi: true
  }]
})
export class IsbnValidatorDirective implements Validator {
  // Checks for ISBN-10 or ISBN-13 format
  regex: RegExp = new RegExp('^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$');

  public constructor() {
  }

  public validate(control: AbstractControl): { [key: string]: any } | null {

    return (this.regex.test(control.value)) ? null : {isInvalidIsbn: true};
  }
}
