import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';

import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import {AppConstants} from "../../common/app-constants";
import {Advertisement} from "../../model/configuration/advertisement";
import {ErrorDto} from "../../model/error/error-dto";
import {GenericConfigService} from "../../service/generic-config.service";

@Component({
  selector: 'app-advertisement-view',
  templateUrl: './advertisement-view.component.html'
})
export class AdvertisementViewComponent implements OnInit, OnDestroy {
  faTrash = AppConstants.iconTrash;
  faEdit = AppConstants.iconEdit;
  faArrowLeft = AppConstants.iconArrowLeft;
  advertisement: Advertisement = new Advertisement();
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto = new ErrorDto();

  constructor(private genericConfigService: GenericConfigService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadConfigs();
  }

  private loadConfigs() {
    this.spinner.show('accountSpinner').then(r => r);
    this.genericConfigService.getGenericConfigs(ModuleEnum.FRONTEND_ADMIN, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: genericConfigResponse => {
          this.spinner.hide('accountSpinner').then(() => {
            this.advertisement = Advertisement.mapFrom(genericConfigResponse.data);
            this.errorDto = new ErrorDto();
          });
        },
        error: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' config reloaded failed', 'Config');
            });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
