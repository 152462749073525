import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseService} from "../common/abstract/base-service";
import {ErrorDto} from "../model/error/error-dto";
import {AppConstants} from "../common/app-constants";
import {catchError} from "rxjs/operators";
import {OrderListResponseDto} from "../model/order/order-list-response-dto";
import {OrderResponseDto} from "../model/order/order-response-dto";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {

  private orderEndpoint = AppConstants.baseUrl + '/api/orders';
  private orderFilterEndpoint = this.orderEndpoint + '/filter'

  constructor(private httpClient: HttpClient) {
    super();
  }

  public deleteOrder(orderId: String, errorDto: ErrorDto) {
    const accountUrl = this.addParamIntoUrl(this.orderEndpoint, orderId);
    return this.httpClient.delete(accountUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getOrderDetail(orderId: string, errorDto: ErrorDto) {
    const orderResponseUrl = this.addParamIntoUrl(this.orderEndpoint, orderId);
    return this.httpClient.get<OrderResponseDto>(orderResponseUrl, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public listOrders(page: number, errorDto: ErrorDto) {
    let paramsData = new HttpParams()
      .set('pageNumber', String(page))
      .set('pageSize', '10')
      .set('sortBy', 'STATUS');

    // if (name != null) {
    //   paramsData = paramsData.set('name', name);
    // }
    // if (category != null && category.url != null) {
    //   paramsData = paramsData.set('category', category.url);
    // }
    if (page != null) {
      paramsData = paramsData.set('pageNumber', page);
    }

    return this.httpClient.get<OrderListResponseDto>(this.orderFilterEndpoint, {
      headers: AppConstants.acceptJson,
      params: paramsData
    })
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
