<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-md-6 col-sm-8 align-self-center">
      <div class="box-part text-center">
        <h3>Forgotten password</h3>

        <form name="form" #forgotPswdForm="ngForm"
              (ngSubmit)="forgotPswdForm.form.valid && forgotPassword()"
              novalidate>

          <img alt="user" width="120" height="120"
               class="profile-img-card mb-4"
               id="profile-img"
               src="{{currentImgPath}}"/>

          <div class="input-group input-group-lg mb-4">
            <span class="input-group-text"><fa-icon [icon]="faUser"></fa-icon></span>
            <input
              placeholder="email"
              type="*email"
              class="form-control"
              name="email"
              [(ngModel)]="email"
              #catEmail="ngModel"
              required
            />
          </div>

          <div *ngIf="forgotPswdForm.submitted && catEmail.invalid" class="alert alert-danger">
            <div *ngIf="catEmail.errors.required">Email is required.</div>
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="row">
            <div class="col">
              <button class="btn btn-primary btn-block mb-3" type="submit">Send link</button>
            </div>
          </div>
          <div class="mb-3">
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="errorDto?.httpStatus">
              {{ errorDto.httpStatusMessage }}<br>
              {{ errorDto.errorMessage }}
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
