import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[appUrlValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: UrlValidatorDirective,
    multi: true
  }]
})
export class UrlValidatorDirective {

  regex: RegExp = new RegExp('^[a-zA-Z0-9]{3,13}[-]{0,1}[a-zA-Z0-9]{0,13}$');

  constructor() {
  }

  public validate(control: AbstractControl): { [key: string]: any } | null {

    return (this.regex.test(control.value)) ? null : {isInvalidUrl: true};
  }
}

