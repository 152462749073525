import {Component, OnInit} from '@angular/core';
import {OrderService} from "../../service/order.service";
import {ErrorDto} from "../../model/error/error-dto";
import {Subject} from "rxjs";
import {NgxSpinnerService} from "ngx-spinner";
import {faEdit, faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import {OrderDetailOutputDto} from "../../model/order/order-detail-output-dto";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import {AppConstants} from "../../common/app-constants";

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit {

  protected readonly faTrash = faTrash;
  protected readonly faEdit = faEdit;
  protected readonly faEye = faEye;
  orders: OrderDetailOutputDto[]
  errorDto: ErrorDto = new ErrorDto();
  info: string;
  destroy$ = new Subject<void>();

  constructor(private orderService: OrderService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadOrders();
  }

  loadOrders() {
    this.spinner.show('orderListSpinner').then(r => r);
    this.orderService.listOrders(0, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('orderListSpinner').then(
            () => this.toastr.info(response.data.length + ' orders reloaded', 'OrderList'));
          this.orders = response.data;
        },
        error: () => {
          this.spinner.hide('orderListSpinner').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' load orders failed', 'OrderList');
              this.orders = new Array<OrderDetailOutputDto>();
            });
        }
      })
  }

  removeOrder(orderId: number) {
    if (!confirm('Are you sure to delete account?' + orderId + '?')) {
      return;
    }
    this.spinner.show().then(r => r);
    this.orderService.deleteOrder(orderId.toString(), this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.info = 'Order id ' + orderId + ' deleted';
              this.errorDto = new ErrorDto();
              this.toastr.success(' 204 account ' + orderId + ' deleted', 'Account');
              setTimeout(() => {
                this.info = null;
              }, AppConstants.toastSuccessDelay);
              this.loadOrders();
            }
          );
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(+this.errorDto.httpStatus + ' delete failed', 'Account')
          );
        }
      });
  }


}
