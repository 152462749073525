<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-12 align-self-center">
      <div class="box-part text-center">
        <h3 class="text-orange mb-3">Accounts</h3>

        <div class="row">
          <div class="input-group d-none d-sm-inline-flex text-center">
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Username</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Email</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Role</span>
            </div>
            <div class="col-12 col-sm-2" [ngClass]="{'col-sm-3': !showDelete()}">
              <span class="text-darkorange">Status</span>
            </div>
            <div class="col-12 col-sm-1">
              <span>
                <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
              </span>
            </div>
            <div *ngIf="showDelete()" [ngClass]="{'d-col-sm-0': !showDelete()}" class="col-12 col-sm-1">
              <span>
               <fa-icon class="text-darkorange" [icon]="faDelete"></fa-icon>
              </span>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row text-center text-sm-start" *ngFor="let admin of adminList">
          <div class="input-group link">
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Username"></span>
              <span class="d-inline-block m-2 text-white">{{admin.username}}</span>
            </div>
            <div class="col-12 col-sm-4 text-white border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Email"></span>
              <span class="d-inline-block m-2 text-white">{{admin.email}}</span>
            </div>
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Role"></span>
              <span class="d-inline-block m-2 text-white">{{admin.role}}</span>
            </div>
            <div class="col-12 col-sm-2 border-bottom" [ngClass]="{'col-sm-3': !showDelete()}">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Status"></span>
              <span class="d-inline-block m-2 text-white">{{admin.status}}</span>
            </div>
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button class="btn btn-primary btn-sm m-2" routerLinkActive="active"
                      [routerLink]="['/accounts/edit/', admin.username]">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>
            <div *ngIf="showDelete()" [ngClass]="{'col-sm-0': !showDelete()}" class="col-12 col-sm-1 border-bottom text-center">
              <button (click)="deleteAccount(admin.username)" class="btn btn-danger btn-sm m-2" routerLinkActive="active">
                <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <app-info [info]="info"></app-info>
        <app-error [errorDto]="errorDto"></app-error>
        <button class="btn btn-primary" routerLinkActive="active" routerLink="/accounts/create">Create account</button>
      </div>
    </div>
  </div>
</div>
