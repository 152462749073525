import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {AccountCreateComponent} from "./account-create/account-create.component";
import {AccountEditComponent} from "./account-edit/account-edit.component";
import {AccountListComponent} from "./account-list/account-list.component";
import {FormsModule} from "@angular/forms";
import {NgxSpinnerModule} from "ngx-spinner";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [
    AccountListComponent,
    AccountEditComponent,
    AccountCreateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxSpinnerModule,
    FaIconComponent,
    RouterLink
  ]
})
export class AccountModule {
}
