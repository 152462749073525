<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-10 align-self-center">
      <div class="box-part text-center">
        <form #createGenericForm="ngForm" novalidate
              (ngSubmit)="createGenericForm.form.valid && genericConfigSaveOperation()">

          <h2>{{this.getAction()}} generic config</h2>
          <div class=detail>
            <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                         size="medium"
                         color="#fff"
                         type="ball-spin-clockwise">
            </ngx-spinner>
          </div>
          <div class="mb-3">
            <label for="module-input">Module</label>
            <select id="module-input" class="form-select form-select-md" name="configModule"
                    [(ngModel)]="genericConfigRequest.key.module" #configModule="ngModel" required>
              <option *ngFor="let module of availableModules" [ngValue]="module">
                {{module}}
              </option>
            </select>

            <div *ngIf="createGenericForm.submitted && configModule.errors"
                 class="alert alert-danger">
              <div *ngIf="configModule.errors.required"> Key is required</div>
            </div>
          </div>


          <div class="mb-3">
            <label for="config-key">Key</label>
            <input id="config-key" name="configKey" class="form-control" type="text"
                   placeholder="required SOME_KEY"
                   [(ngModel)]="genericConfigRequest.key.name" #configKey="ngModel" required>

            <div *ngIf="createGenericForm.submitted && configKey.errors"
                 class="alert alert-danger">
              <div *ngIf="configKey.errors.required"> Key is required</div>
            </div>
          </div>

          <div class="mb-3">
            <label for="configType-input">Config type</label>
            <select id="configType-input" class="form-select form-select-md" name="configType"
                    [(ngModel)]="genericConfigRequest.type" #configType="ngModel" required>
              <option *ngFor="let type of availableConfigTypes" [ngValue]="type">
                {{type}}
              </option>
            </select>

            <div *ngIf="createGenericForm.submitted && configType.errors"
                 class="alert alert-danger">
              <div *ngIf="configType.errors.required"> Config Type is required</div>
            </div>
          </div>

          <div class="mb-3">
            <label for="config-value">Value</label>
            <input id="config-value" name="configValue" class="form-control" type="text"
                   placeholder="required lorem ipsum"
                   [(ngModel)]="genericConfigRequest.value" #configValue="ngModel" required>

            <div *ngIf="createGenericForm.submitted && configValue.errors"
                 class="alert alert-danger">
              <div *ngIf="configValue.errors.required"> Value is required</div>
            </div>
          </div>

          <app-error [errorDto]="errorDto"></app-error>

          <div class="mb-3">
            <button class="btn btn-primary" type="submit">{{this.getAction()}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


