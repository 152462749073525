import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {authGuard} from './auth-guard';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {QrComponent} from './qr/qr.component';
import {WriteUsListComponent} from "./write-us/write-us-list/write-us-list.component";
import {WriteUsComponent} from "./write-us/write-us/write-us.component";
import {VariantCatalogListComponent} from "./variant-catalog-list/variant-catalog-list.component";
import {UpsertVariantCatalogComponent} from "./upsert-variant-catalog/upsert-variant-catalog.component";
import {GraphHomeComponent} from "./graph-home/graph-home.component";
import {OrderListComponent} from "./order/order-list/order-list.component";
import {OrderViewComponent} from "./order/order-view/order-view.component";
import {GenericConfigListComponent} from "./genericconfig/generic-config-list/generic-config-list.component";
import {GenericConfigCreateComponent} from "./genericconfig/generic-config-create/generic-config-create.component";
import {GenericConfigEditComponent} from "./genericconfig/generic-config-edit/generic-config-edit.component";
import {CategoryListComponent} from "./category/category-list/category-list.component";
import {CategoryUpsertComponent} from "./category/category-upsert/category-upsert.component";
import {CategoryViewComponent} from "./category/category-view/category-view.component";
import {AccountListComponent} from "./account/account-list/account-list.component";
import {AccountEditComponent} from "./account/account-edit/account-edit.component";
import {AccountCreateComponent} from "./account/account-create/account-create.component";
import {ProductListComponent} from "./product/product-list/product-list.component";
import {ProductViewComponent} from "./product/product-view/product-view.component";
import {ProductUpsertComponent} from "./product/product-upsert/product-upsert.component";
import {ProductSkuComponent} from "./product/product-sku/product-sku.component";
import {ProductSkuViewComponent} from "./product/sku-view/product-sku-view.component";
import {ImgConfigurationComponent} from "./genericconfig/img-configuration/img-configuration.component";
import {AdvertisementViewComponent} from "./genericconfig/advertisement-view/advertisement-view.component";
import {BankConfigurationComponent} from "./genericconfig/bank-configuration/bank-configuration.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'home', component: HomeComponent, canActivate: [authGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'password-change', component: PasswordChangeComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'advertisement', component: AdvertisementViewComponent, canActivate: [authGuard]},
  {path: 'bank', component: BankConfigurationComponent, canActivate: [authGuard]},
  {path: 'img', component: ImgConfigurationComponent, canActivate: [authGuard]},
  {path: 'qr-code', component: QrComponent, canActivate: [authGuard]},
  {path: 'categories', component: CategoryListComponent, canActivate: [authGuard]},
  {path: 'categories/upsert', component: CategoryUpsertComponent, canActivate: [authGuard]},
  {path: 'categories/view/:categoryUrl', component: CategoryViewComponent, canActivate: [authGuard]},
  {path: 'products', component: ProductListComponent, canActivate: [authGuard]},
  {path: 'products/view/:productUrl', component: ProductViewComponent, canActivate: [authGuard]},
  {path: 'products/upsert', component: ProductUpsertComponent, canActivate: [authGuard]},
  {path: 'products/sku/:productUrl', component: ProductSkuComponent, canActivate: [authGuard]},
  {path: 'products/sku/view/:sku', component: ProductSkuViewComponent, canActivate: [authGuard]},
  {path: 'accounts', component: AccountListComponent, canActivate: [authGuard]},
  {path: 'accounts/edit/:username', component: AccountEditComponent, canActivate: [authGuard]},
  {path: 'accounts/create', component: AccountCreateComponent, canActivate: [authGuard]},
  {path: 'generic-configs', component: GenericConfigListComponent, canActivate: [authGuard]},
  {path: 'generic-configs/create', component: GenericConfigCreateComponent, canActivate: [authGuard]},
  {path: 'generic-configs/edit/:module/:key', component: GenericConfigEditComponent, canActivate: [authGuard]},
  {path: 'email/write-us', component: WriteUsListComponent, canActivate: [authGuard]},
  {path: 'email/write-us/:email', component: WriteUsComponent, canActivate: [authGuard]},
  {path: 'variant-catalogs', component: VariantCatalogListComponent, canActivate: [authGuard]},
  {path: 'variant-catalogs/create', component: UpsertVariantCatalogComponent, canActivate: [authGuard]},
  {path: 'variant-catalogs/:paramName', component: UpsertVariantCatalogComponent, canActivate: [authGuard]},
  {path: 'graph/home', component: GraphHomeComponent, canActivate: [authGuard]},
  {path: 'orders', component: OrderListComponent, canActivate: [authGuard]},
  {path: 'orders/view/:orderId', component: OrderViewComponent, canActivate: [authGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
