<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-8 align-self-center">
      <div class="box-part text-center text-white">
        <h2>Category Detail</h2>
        <hr>
        <span>name: {{selectedCategory.name}}</span>
        <div class="mb-3">
          <div class="pointer"
               [routerLink]="['/categories/upsert']" [queryParams]="{categoryUrl: selectedOriginalCategoryUrl}">
            <fa-icon class="url" [icon]="faEdit"></fa-icon>&nbsp;{{selectedOriginalCategoryUrl}}
          </div>
        </div>
        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>
        <div class="mb-3 mx-3">
          <span class="trash" *ngIf="!selectedCategory.description"><fa-icon [icon]="faThrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty description!!</span>
          <span class="text-center"> Description</span>
          <div class="content" *ngIf="selectedCategory.description">
            <span>{{selectedCategory.description}}</span>
          </div>
        </div>


        <app-audit [audit]="auditCategory"></app-audit>
        <app-error [errorDto]="errorDto"></app-error>

        <button type="button" class="btn btn-danger" appBackButton>
          <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
        </button>

      </div>
    </div>
  </div>
</div>
