<div class="accordion" id="accordionForGraphs">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Categories
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <ngx-charts-bar-vertical
          [view]="[1000,400]"
          [results]="categoryToProductCount"
          [xAxisLabel]="'Categories'"
          [legendTitle]="'Categories'"
          [yAxisLabel]="'Products count'"
          [legend]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxis]="true"
          [yAxis]="true"
          [gradient]="true">
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Eshop overview
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <ngx-charts-number-card
          [view]="view"
          [results]="single"
          [cardColor]='"#232837"'
          (select)="onSelect($event)">
        </ngx-charts-number-card>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Orders
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <ngx-charts-advanced-pie-chart
            class="col"
            [view]="view"
            [results]="orderStatuses"
            [gradient]="true">
          </ngx-charts-advanced-pie-chart>
          <ngx-charts-line-chart
            class="col"
            [view]="view"
            [legend]="true"
            [legendTitle]='"Order price(day)"'
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [xAxisLabel]='"Date"'
            [yAxisLabel]='"Price total"'
            [timeline]="true"
            [results]="orderPriceTotalPerDay"
          >
          </ngx-charts-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
