<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-8 align-self-center">
      <div class="box-part text-center text-white">
        <h2>Email Detail</h2>
        <span class="d-block">{{emailAddress}}</span>
        <hr>
        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>


        <div class="card">
          <div class="card-body" *ngFor="let email of emails">
            <div
              class="d-block d-sm-flex flex-row justify-content-start mb-1 mb-sm-3 ms-0 ms-sm-2 pt-0 pt-sm-2 text-black">
              <span class="d-inline-block d-sm-inline mb-auto">
              <img src="{{imgLoginPath}}"
                   alt="john doe" class="img-customizer d-none d-sm-flex">
              <span class="text-start customize-short mt-auto">{{email.audit.createdAt| date:"dd.MM.yyyy hh:mm"}}</span>
              </span>
              <div class="p-2 p-sm-3 ms-1 ms-sm-3 card-body-chat h-auto d-block d-sm-flex">
                <p class="small mb-0">{{email.body}}</p>
              </div>
            </div>

            <div *ngIf="email.answer"
                 class="d-block d-sm-flex flex-row justify-content-start ms-0 ms-sm-4 pt-0 pt-sm-2 text-black">
              <span class="d-inline-block d-sm-inline mb-auto">
              <img src="{{imgLoginPath}}"
                   alt="john doe" class="img-customizer d-none d-sm-flex">
              <span
                class="text-start customize-short mt-auto">{{email.answer.audit.createdAt| date:"dd.MM.yyyy hh:mm"}}</span>
              </span>
              <div class="p-2 p-sm-3 ms-1 ms-sm-3 card-body-chat-answer h-auto d-block d-sm-flex">
                <div class="small mb-0" [innerHTML]="email.answer.body"></div>
              </div>
            </div>
          </div>
          <div class="mb-3"></div>
        </div>


        <form #writeUsForm="ngForm" novalidate (ngSubmit)="writeUsForm.valid && response(writeUsForm)">
          <div class="m-2" *ngIf="emailAddress && !hideAnswer()">
            <label for="product-edited-description" class="form-label pb-1 pt-3">Odpověď</label>
            <div *ngIf="writeUsForm.submitted && description.invalid" class="alert alert-danger">
              <div *ngIf="description.errors.required">Není vyplněno.</div>
              <div *ngIf="description.errors.minlength">Odpoveď musí mít alespoň 20 znaků.</div>
            </div>
            <angular-editor id="product-edited-description" name="description" [placeholder]="'Enter text here...'"
                            [(ngModel)]="htmlContent" [config]="editorConfig" class="form-control"
                            #description="ngModel" minlength="20" required>

            </angular-editor>
          </div>
          <div class="card-body">
            <app-info [info]="info"></app-info>
          </div>
          <div class="card-body">
            <app-error [errorDto]="errorDto"></app-error>
          </div>

          <div class="mt-4" *ngIf="hideAnswer()"></div>
          <div class="col-auto">
            <button type="submit"
                    class="ms-3 ms-sm-5 btn btn-primary" *ngIf="!hideAnswer()">Odeslat
            </button>
            <button type="button" class="ms-3 ms-sm-5 btn btn-danger" appBackButton>
              <fa-icon [icon]="faArrowLeft" class="me-2"></fa-icon>
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

