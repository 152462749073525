import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {GenericConfigService} from "../../service/generic-config.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import ModuleEnum = GenericConfigKeyDto.ModuleEnum;

declare var bootstrap: any;

@Component({
  selector: 'app-img-configuration',
  templateUrl: './img-configuration.component.html'
})
export class ImgConfigurationComponent implements OnInit, OnDestroy {
  faArrowLeft = AppConstants.iconArrowLeft;
  faUser = AppConstants.iconUser;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto: ErrorDto = new ErrorDto();
  private readonly ESHOP_IMG_SIZE = "IMG_SIZE";
  private readonly ESHOP_IMG_DIMENSION = "IMG_DIMENSION";
  maxDimensionSize: string;
  maxFileSize: string;

  constructor(private genericConfigService: GenericConfigService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    // Bootstrap tooltip initialization
    [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    this.getValueFileSize();
    this.getDimensionSize();
  }

  getValueFileSize() {
    this.spinner.show().then(r => r);
    this.genericConfigService.getGenericConfigBy(ModuleEnum.FRONTEND_ADMIN, this.ESHOP_IMG_SIZE, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: config => {
          this.spinner.hide().then(() => {
            this.errorDto = new ErrorDto();
            this.maxFileSize = config.data.value;
          });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' file size load failed', 'Img Config')
          )
        }
      });
  }

  getDimensionSize() {
    this.spinner.show().then(r => r);
    this.genericConfigService.getGenericConfigBy(ModuleEnum.FRONTEND_ADMIN, this.ESHOP_IMG_DIMENSION, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: config => {
          this.spinner.hide().then(() => {
            this.errorDto = new ErrorDto();
            this.maxDimensionSize = config.data.value;
          });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' dimension size load failed', 'Img Config')
          )
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
