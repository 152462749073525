import {Injectable} from '@angular/core';
import {AppConstants} from '../common/app-constants';
import {BaseService} from '../common/abstract/base-service';
import {catchError} from 'rxjs/operators';
import {QrRequest} from '../model/qr/QrRequest';
import {HttpClient} from '@angular/common/http';
import {QrDataResponse} from '../model/qr/QrDataResponse';
import {QrDataRequest} from '../model/qr/QrDataRequest';
import {ErrorDto} from '../model/error/error-dto';

@Injectable({
  providedIn: 'root'
})
export class QrService extends BaseService {
  private qrResponse = AppConstants.baseUrl + '/api/payment/qr-code';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public loadQr(qrRequest: QrRequest, errorDto: ErrorDto) {
    const body = JSON.stringify(new QrDataRequest(qrRequest));
    return this.httpClient.post<QrDataResponse>(this.qrResponse, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
