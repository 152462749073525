import {Pipe, PipeTransform} from '@angular/core';
import {MoneyDto} from "../model/order/order-item-response-dto";

@Pipe({
  name: 'pricePipe'
})
export class PricePipe implements PipeTransform {

  transform(money: MoneyDto, ...args: unknown[]): unknown {
    return money.price + ' ' + money.currencyCode
  }
}
