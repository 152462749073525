export class VariantDetail {
  name: string;
  arrangement: number;

  constructor(name: string, arrangement: number) {
    this.name = name;
    this.arrangement = arrangement;
  }

}
