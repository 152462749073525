import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../common/app-constants';
import {BaseService} from '../common/abstract/base-service';
import {ErrorDto} from "../model/error/error-dto";
import {VariantCategoryResponses} from "../model/variant-category/variant-category-responses";
import {VariantCatalog} from "../model/variant-category/variant-catalog";
import {VariantCategoryWrapper} from "../model/variant-category/variant-category-wrapper";
import {VariantDetail} from "../model/variant-category/variant-detail";
import {VariantsRequest} from "../model/variant-category/variants-request";

@Injectable({
  providedIn: 'root'
})
export class VariantCatalogService extends BaseService {

  private variantCategoryUri = AppConstants.baseUrl + '/api/variant-catalog';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listAll(errorDto: ErrorDto) {
    return this.httpClient.get<VariantCategoryResponses>(this.variantCategoryUri.concat("/all"), {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public deleteVariantCatalog(variantCategory: VariantCatalog, errorDto: ErrorDto) {
    const deleteVariantUrl = this.addParamIntoUrl(this.variantCategoryUri, variantCategory.paramName);
    return this.httpClient.delete(deleteVariantUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public deleteVariant(variantName: VariantDetail, errorDto: ErrorDto) {
    const deleteVariantUrl = this.addParamIntoUrl(this.variantCategoryUri.concat("/variant"), variantName.name);
    return this.httpClient.delete(deleteVariantUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public getVariantCatalog(pathParamName: string, errorDto: ErrorDto) {
    const getVariantUrl = this.addParamIntoUrl(this.variantCategoryUri, pathParamName);
    return this.httpClient.get<VariantCategoryWrapper>(getVariantUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public createVariantCatalog(variantCategory: VariantCatalog, errorDto: ErrorDto) {
    const body = JSON.stringify(new VariantCategoryWrapper(variantCategory));
    return this.httpClient.post<VariantCategoryWrapper>(this.variantCategoryUri, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public putVariantCatalog(originalPathParam: string, variantCategory: VariantCatalog, errorDto: ErrorDto) {
    const putVariantUrl = this.addParamIntoUrl(this.variantCategoryUri, originalPathParam);
    const body = JSON.stringify(new VariantCategoryWrapper(variantCategory));
    return this.httpClient.put<VariantCategoryWrapper>(putVariantUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public addVariants(categoryVariantParamName: string, variantCategory: VariantsRequest, errorDto: ErrorDto) {
    const putVariantsUrl = this.addParamIntoUrl(this.variantCategoryUri.concat("/add-variants"), categoryVariantParamName);
    const body = JSON.stringify(variantCategory);
    return this.httpClient.post<VariantCategoryWrapper>(putVariantsUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }
  public updateVariantsArrangement(categoryVariantParamName: string, variantCategory: VariantCatalog, errorDto: ErrorDto) {
    const putVariantsUrl = this.addParamIntoUrl(this.variantCategoryUri.concat("/upsert-variants-arrangement"), categoryVariantParamName);
    const body = JSON.stringify(new VariantsRequest(variantCategory.variants));
    return this.httpClient.post<VariantCategoryWrapper>(putVariantsUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }
}
