import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from "../common/app-constants";
import {Subject} from "rxjs";
import {ErrorDto} from "../model/error/error-dto";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {VariantCatalogService} from "../service/variant-catalog.service";
import {VariantCatalog} from "../model/variant-category/variant-catalog";
import {takeUntil} from "rxjs/operators";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {VariantDetail} from "../model/variant-category/variant-detail";
import {VariantsRequest} from "../model/variant-category/variants-request";

@Component({
  selector: 'app-upsert-variant-catalog',
  templateUrl: './upsert-variant-catalog.component.html',
  styleUrls: ['./upsert-variant-catalog.component.css']
})
export class UpsertVariantCatalogComponent implements OnInit, OnDestroy {
  originalPathParamName: string;
  variantCatalog = new VariantCatalog();
  faArrowLeft = AppConstants.iconArrowLeft;
  faPlus = AppConstants.iconPlus;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto = new ErrorDto();
  operation: string;
  faDelete = AppConstants.iconDelete;
  form: FormGroup;
  faArrowUp = AppConstants.iconArrowUp;
  faArrowDown = AppConstants.iconArrowDown;
  visible = false;

  constructor(private variantCatalogService: VariantCatalogService,
              public route: Router,
              private actRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.originalPathParamName = params.get('paramName');
      this.findByParamName();
    });

    this.form = new FormGroup({
      variant: new FormArray([])
    });
  }

  get variant(): FormArray {
    return this.form.get('variant') as FormArray;
  }

  findByParamName() {
    if (!this.originalPathParamName) {
      this.operation = "Create";
      this.variantCatalog = new VariantCatalog();
      return;
    }
    this.operation = "Update";
    this.spinner.show('Variant Catalog').then(r => r);
    this.variantCatalogService.getVariantCatalog(this.originalPathParamName, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('Variant Catalog').then(() => {
            this.variantCatalog = response.data;
            this.variantCatalog.variants = this.variantCatalog.variants.sort((a, b) => a.arrangement - b.arrangement)
          });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error('Loading variant catalog failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }

  deleteVariant(variant: VariantDetail) {
    if (!confirm('Are you sure to delete variant?' + variant.name + '?')) {
      return;
    }
    this.spinner.show('Variant Catalog').then(r => r);
    this.variantCatalogService.deleteVariant(variant, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('Variant Catalog').then(() => {
            this.toastr.success(variant.name + " deleted");
            this.findByParamName();
          });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error('Delete variant failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }

  upsertCatalogVariant() {
    if (this.operation == "Create") {
      this.createCatalogVariant();
    }
    if (this.operation == "Update") {
      this.updateCatalogVariant();
    }
  }

  updateCatalogVariant() {
    this.variantCatalogService.putVariantCatalog(this.originalPathParamName, this.variantCatalog, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('Variant Catalog').then(() => {
            this.variantCatalog = response.data;
            setTimeout(() => {
              this.route.navigate(['/variant-catalogs']);
            }, AppConstants.toastSuccessDelay);
          });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error('Create catalog failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }

  createCatalogVariant() {
    this.variantCatalogService.createVariantCatalog(this.variantCatalog, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('Variant Catalog').then(() => {
            this.variantCatalog = response.data;
            setTimeout(() => {
              this.route.navigate(['/variant-catalogs']);
            }, AppConstants.toastSuccessDelay);
          });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error('Create catalog failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }

  addVariant() {
    this.variant.setValidators(Validators.required)
    this.variant.push(
      new FormGroup({
        name: new FormControl(''),
        index: new FormControl(this.variant.length + 1)
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  removeAddedVariant(index: number) {
    this.variant.removeAt(index)
  }

  addVariants() {
    const variantDetail: VariantDetail[] = [];
    const variantsRequests = new VariantsRequest(variantDetail)
    this.variant.getRawValue().forEach(data => variantDetail.push(new VariantDetail(data.name, data.index)))
    this.variantCatalogService.addVariants(this.originalPathParamName, variantsRequests, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('Variant Catalog').then(() => {
            this.variantCatalog = response.data;
            this.form = new FormGroup({
              variant: new FormArray([])
            });
            this.findByParamName();
          });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error('Addind variant to catalog failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }

  down(variant: VariantDetail, i: number) {
    this.visible = true;
    const val = this.variantCatalog.variants[i + 1];
    this.variantCatalog.variants[i + 1] = this.variantCatalog.variants[i]
    this.variantCatalog.variants[i] = val
    this.reArrangement();
  }

  up(variant: VariantDetail, i: number) {
    this.visible = true;
    const val = this.variantCatalog.variants[i - 1];
    this.variantCatalog.variants[i - 1] = this.variantCatalog.variants[i]
    this.variantCatalog.variants[i] = val
    this.reArrangement();
  }
  reArrangement(){
    this.variantCatalog.variants.forEach( (val, index) => {
          val.arrangement = index;
    } )
  }

  upsertArrangement() {
    this.spinner.show('variant').then(r => r);
    this.variantCatalogService.updateVariantsArrangement(this.originalPathParamName, this.variantCatalog, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.visible = false;
          this.spinner.hide('variant').then(() => {
            this.variantCatalog = response.data;
            this.variantCatalog.variants = this.variantCatalog.variants.sort((a, b) => a.arrangement - b.arrangement)
          });
        },
        error: () => {
          this.visible = false;
          this.spinner.hide('variant').then(
            () => {
              this.toastr.error('Upsert variants arrangement failed ' + this.errorDto.httpStatus, 'Variant Catalog');
            });
        }
      });
  }
}
