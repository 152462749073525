import {Directive} from '@angular/core';
import {Validator, AbstractControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[appPriceValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PriceValidatorDirective,
    multi: true
  }]
})
export class PriceValidatorDirective implements Validator {

  regex: RegExp = new RegExp('^(?:0|[1-9]\\d{0,7})(?:\\.\\d+)?$');

  public constructor() {
  }

  public validate(control: AbstractControl): { [key: string]: any } | null {

    return (this.regex.test(control.value)) ? null : {isInvalidPrice: true};
  }
}
