<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-3 col-md-10 col-sm-12 align-self-center">
      <div class="box-part text-center">
        <form #createAccountForm="ngForm" novalidate (ngSubmit)="createAccountForm.form.valid && createAccount()">
          <h2>Create account</h2>
          <div class="mb-1">
            <label for="account-username" class="col-form-label">Username</label>
            <input id="account-username" name="username" class="form-control" type="text"
                   placeholder="required"
                   required minlength="4"
                   [(ngModel)]="account.username" #username="ngModel">

            <div class="mt-1">
              <div *ngIf="createAccountForm.submitted && username.invalid" class="alert alert-danger">
                <div *ngIf="username.errors.required">Name is required.</div>
                <div *ngIf="username.errors.minlength"> Name must be at least 4 characters long.</div>
              </div>
            </div>
          </div>

          <div class="mb-1">
            <label for="account-email" class="col-form-label">Email</label>
            <input id="account-email" name="email" class="form-control" type="text"
                   placeholder="required"
                   required minlength="4"
                   [(ngModel)]="account.email" #email="ngModel">
          </div>
          <div *ngIf="createAccountForm.submitted && email.invalid" class="alert alert-danger mt-1">
            <div *ngIf="email.errors.required">Email is required.</div>
          </div>

          <div class="mb-1">
            <label for="account-role" class="col-form-label">Role</label>
            <select class="form-select form-select-lg mb-3"
                    [(ngModel)]="account.role"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="selectRole($event)"
                    #select="ngModel"
                    id="account-role"
                    minlength="4">
              <option *ngFor="let role of roles" [ngValue]="roleHelper[role]">
                {{role}}
              </option>
            </select>
          </div>

          <div *ngIf="createAccountForm.submitted && select.invalid"
               class="alert alert-danger">
            <div *ngIf="select.errors.required">
              ROle must be selected.
            </div>
            <div *ngIf="select.errors.minlength">
              Role must be at least 4 characters long.
            </div>
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="mb-3">
            <div
              class="alert alert-success"
              role="alert"
              *ngIf="info">{{ info }}
            </div>
          </div>

          <app-error [errorDto]="errorDto"></app-error>

          <div class="mb-3">
            <button type="submit" class="btn btn-primary m-3">Create
            </button>
            <button type="button" class="ms-2 btn btn-danger m-3" appBackButton>
              <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
