import {Role} from './role';

export class Admin {
  username: string;
  expiration: number;
  token: string;
  email: string;
  role: Role;
  status: string;
}
