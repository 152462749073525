<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-12 align-self-center">
      <div class="box-part text-center">
        <h3 class="text-orange mb-3">Variant catalog</h3>

        <div class="row">
          <div class="input-group d-none d-sm-inline-flex text-center">
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Param Name</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Name</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Varianty [ks]</span>
            </div>
            <div class="col-12 col-sm-1">
              <span>
                <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
              </span>
            </div>
            <div class="col-12 col-sm-1">
              <span>
               <fa-icon class="text-darkorange" [icon]="faDelete"></fa-icon>
              </span>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row text-center text-sm-center" *ngFor="let variantCategory of variantCategories">
          <div class="input-group link">
            <div class="col-12 col-sm-4 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Param Name"></span>
              <span class="d-inline-block m-2 text-white">{{variantCategory.paramName}}</span>
            </div>
            <div class="col-12 col-sm-4 text-white border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
              <span class="d-inline-block m-2 text-white">{{variantCategory.name}}</span>
            </div>
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Variants"></span>
              <span class="d-inline-block m-2 text-white">{{variantCategory.variants?.length}}</span>
            </div>
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button class="btn btn-primary btn-sm m-2" routerLinkActive="active"
                      [routerLink]="['/variant-catalogs/', variantCategory.paramName]">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button (click)="deleteVariantCatalog(variantCategory)" class="btn btn-danger btn-sm m-2"
                      routerLinkActive="active">
                <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <app-error [errorDto]="errorDto"></app-error>
        <button class="btn btn-primary" routerLinkActive="active" [routerLink]="['/variant-catalogs/create']">Create catalog
        </button>
      </div>
    </div>
  </div>
</div>
