import {GenericConfigDataDto} from "../generic-config/generic-config-data-dto";
import {Config} from "./config"

export class Advertisement extends Config {
  who: string;
  address: string;
  complaintDays: string;
  consumer: string;
  complaintDaysDelay: string;
  dueDate: string;
  ico: string;
  phone: string;
  emailWho: string;
  eshopEmail: string;
  webEshopAddress: string;
  sortiment: string;
  eshopName: string;
  limitFreeShippingPrice: string;
  private static readonly ADVERTISEMENT_ADDRESS = "ADVERTISEMENT_ADDRESS";
  private static readonly ADVERTISEMENT_ICO = "ADVERTISEMENT_ICO";
  private static readonly ADVERTISEMENT_PHONE = "ADVERTISEMENT_PHONE";
  private static readonly ADVERTISEMENT_WHO = "ADVERTISEMENT_WHO";
  private static readonly ADVERTISEMENT_COMPLAINT_DAYS = "ADVERTISEMENT_COMPLAINT_DAYS";
  private static readonly ADVERTISEMENT_COMPLAINT_DAYS_DELAY = "ADVERTISEMENT_COMPLAINT_DAYS_DELAY";
  private static readonly ADVERTISEMENT_DUE_DATE = "ADVERTISEMENT_DUE_DATE";
  private static readonly ADVERTISEMENT_CONSUMER = "ADVERTISEMENT_CONSUMER";
  private static readonly ADVERTISEMENT_NAME = "ADVERTISEMENT_NAME";
  private static readonly ADVERTISEMENT_SORTIMENT = "ADVERTISEMENT_SORTIMENT";
  private static readonly ADVERTISEMENT_EMAIL = "ADVERTISEMENT_EMAIL"
  private static readonly ADVERTISEMENT_EMAIL_WHO = "ADVERTISEMENT_EMAIL_WHO"
  private static readonly ADVERTISEMENT_WEB_ADDRESS = "ADVERTISEMENT_WEB_ADDRESS"
  private static readonly ADVERTISEMENT_FREE_SHIPPING_CZ = "ADVERTISEMENT_FREE_SHIPPING_CZK";

  public static mapFrom(data: GenericConfigDataDto[]): Advertisement {
    let advertisement = new Advertisement();
    advertisement.who = super.findValueByKey(this.ADVERTISEMENT_WHO, data);
    advertisement.address = super.findValueByKey(this.ADVERTISEMENT_ADDRESS, data);
    advertisement.complaintDays = super.findValueByKey(this.ADVERTISEMENT_COMPLAINT_DAYS, data);
    advertisement.consumer = super.findValueByKey(this.ADVERTISEMENT_CONSUMER, data);
    advertisement.complaintDaysDelay = super.findValueByKey(this.ADVERTISEMENT_COMPLAINT_DAYS_DELAY, data);
    advertisement.dueDate = super.findValueByKey(this.ADVERTISEMENT_DUE_DATE, data);
    advertisement.ico = super.findValueByKey(this.ADVERTISEMENT_ICO, data);
    advertisement.phone = super.findValueByKey(this.ADVERTISEMENT_PHONE, data);
    advertisement.emailWho = super.findValueByKey(this.ADVERTISEMENT_EMAIL, data);
    advertisement.eshopEmail = super.findValueByKey(this.ADVERTISEMENT_EMAIL_WHO, data);
    advertisement.webEshopAddress = super.findValueByKey(this.ADVERTISEMENT_WEB_ADDRESS, data);
    advertisement.sortiment = super.findValueByKey(this.ADVERTISEMENT_SORTIMENT, data);
    advertisement.eshopName = super.findValueByKey(this.ADVERTISEMENT_NAME, data);
    advertisement.limitFreeShippingPrice = super.findValueByKey(this.ADVERTISEMENT_FREE_SHIPPING_CZ, data);
    return advertisement;
  }
}
