import {Component, Input} from '@angular/core';
import {ErrorDto} from "../model/error/error-dto";

@Component({
  selector: 'app-error',
  template: `
    <div class="mb-3">
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="errorDto.httpStatus">
        {{ errorDto.httpStatusMessage }}<br>
        {{ errorDto.errorMessage }}
      </div>
    </div>
  `

})
export class ErrorComponent {
  @Input() errorDto: ErrorDto;
}
