<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-sm-10 align-self-center">
      <div class="box-part text-center">
        <form class="row px-3" #createQrForm="ngForm" novalidate (ngSubmit)="createQrForm.form.valid && loadQr()">
          <h2 class="mb-4">Generate qr code</h2>
          <div class="col-8">
            <input type="text" class="form-control" id="variableSymbol"
                   [(ngModel)]="qrCodeRequest.iban"
                   #iban="ngModel"
                   name="iban">
          </div>
          <div class="col-4">
            <input type="text" class="form-control" id="account" placeholder="VS"
                   [(ngModel)]="qrCodeRequest.variableSymbol"
                   #variableSymbol="ngModel"
                   name="variableSymbol">
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="large"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
          <div class="row">
            <div class="col  mb-3"><br>
              <button type="submit" class="btn btn-primary ms-2" aria-hidden="true">Generate QR</button>

              <button type="button" class="btn btn-danger ms-4" appBackButton>
                <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
              </button>
            </div>
          </div>

          <app-error [errorDto]="errorDto"></app-error>

        </form>
        <img readonly="true" *ngIf=existingImage class="img-responsive show-image p-3" [src]="existingImage"
             alt="Qr code result">

        <app-info [info]="info"></app-info>

      </div>
    </div>
  </div>
</div>
