import {Injectable} from '@angular/core';
import {AppConstants} from '../common/app-constants';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../common/abstract/base-service';
import {catchError, retry} from 'rxjs/operators';
import {GenericConfigListResponseDto} from '../model/generic-config/generic-config-list-response-dto';
import {GenericConfigKeyDto} from "../model/generic-config/generic-config-key-dto";
import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import {ErrorDto} from '../model/error/error-dto';
import {GenericConfigDataDto} from "../model/generic-config/generic-config-data-dto";
import {GenericConfigCreateDto} from "../model/generic-config/generic-config-create-dto";
import {GenericConfigResponseDto} from "../model/generic-config/generic-config-response-dto";
import {GenericConfigUpdateDto} from "../model/generic-config/generic-config-update-dto";

@Injectable({
  providedIn: 'root'
})
export class GenericConfigService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getGenericConfigs(module: ModuleEnum, errorDto:ErrorDto) {
    let genericConfigEndpoint: string = AppConstants.baseUrl + "/api/generic-config/" + module.toString() + "/by-module";
    return this.httpClient.get<GenericConfigListResponseDto>(genericConfigEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getGenericConfigBy(module: ModuleEnum, key: string, errorDto:ErrorDto) {
    let genericConfigEndpoint: string = AppConstants.baseUrl + "/api/generic-config/"+ module + "/" + key;
    return this.httpClient.get<GenericConfigResponseDto>(genericConfigEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        retry(1),
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public postGenericConfig(createDto: GenericConfigDataDto, errorDto:ErrorDto) {
    let postEndpoint = AppConstants.baseUrl + "/api/generic-config";
    const body = JSON.stringify(new GenericConfigCreateDto(createDto));
    return this.httpClient.post<any>(postEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public putGenericConfig(module: ModuleEnum, key: string, data: string, errorDto:ErrorDto) {
    let genericConfigEndpoint: string = AppConstants.baseUrl + "/api/generic-config/"+ module + "/" + key;
    const body = JSON.stringify(new GenericConfigUpdateDto(data));
    return this.httpClient.put<GenericConfigResponseDto>(genericConfigEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public deleteGenericConfig(module: ModuleEnum, key: string, errorDto:ErrorDto) {
    let genericConfigEndpoint: string = AppConstants.baseUrl + "/api/generic-config/" + module + "/" + key;
    return this.httpClient.delete<any>(genericConfigEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }
}
