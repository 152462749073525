import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../common/app-constants';
import {BaseService} from '../common/abstract/base-service';
import {AdminList} from '../model/admin/admin-list';
import {Admin} from '../model/admin/admin';
import {AdminResponse} from '../model/admin/admin-response';
import {ErrorDto} from '../model/error/error-dto';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  private accountEndpoint = AppConstants.baseUrl + '/api/accounts';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAccounts(errorDto: ErrorDto) {
    return this.httpClient.get<AdminList>(this.accountEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getAccount(username: String, errorDto: ErrorDto) {
    const accountUrl = this.addParamIntoUrl(this.accountEndpoint, username);
    return this.httpClient.get<AdminResponse>(accountUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public editAccount(admin: Admin, username: String, errorDto: ErrorDto) {
    const adminResponse = new AdminResponse();
    adminResponse.data = admin;
    const body = JSON.stringify(adminResponse);
    const accountEndpoint = this.addParamIntoUrl(this.accountEndpoint, username);
    return this.httpClient.put<AdminResponse>(accountEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public createAccount(admin: Admin, errorDto: ErrorDto) {
    const adminResponse = new AdminResponse();
    adminResponse.data = admin;
    const body = JSON.stringify(adminResponse);
    return this.httpClient.post<AdminResponse>(this.accountEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public passwordRecovery(email:string, errorDto: ErrorDto) {
    return this.httpClient.post(this.accountEndpoint.concat('/password-recovery'), email,
      {
        headers: AppConstants.contentTypeAndAcceptJson,
      })
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public passwordChange(credentials, token: string, errorDto: ErrorDto) {
    return this.httpClient.post(this.accountEndpoint.concat('/password-change'), credentials.password,
      {
        headers: AppConstants.contentTypeAndAcceptJson,
        params: new HttpParams().append('token', token)
      })
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public deleteAccount(username: String, errorDto: ErrorDto) {
    const accountUrl = this.addParamIntoUrl(this.accountEndpoint, username);
    return this.httpClient.delete(accountUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto))
      );
  }
}
