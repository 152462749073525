<form #upsertProductSkuForm="ngForm" novalidate (ngSubmit)="upsertProductSkuForm.valid && insertSku()">
  <h2>Add product sku</h2>
  <hr>
  <h5>{{ rootProduct.name }}</h5>

  <div class="mb-3">
    <label for="product-sku-name" class="form-label">Name</label>
    <input id="product-sku-name" name="prodSkuName" class="form-control" type="text"
           placeholder="required"
           required minlength="4"
           [(ngModel)]="productSku.name" #prodSkuName="ngModel">

    <div *ngIf="upsertProductSkuForm.submitted && prodSkuName.invalid" class="alert alert-danger">
      <div *ngIf="prodSkuName.errors.required">Name is required.</div>
      <div *ngIf="prodSkuName.errors.minlength"> Name must be at least 4 characters long.</div>
    </div>
  </div>

  <div class="mb-3">
    <label for="product-sku-sku" class="form-label">Sku</label>
    <input id="product-sku-sku" name="prodSkuSku" class="form-control" type="text"
           placeholder="required"
           required minlength="4"
           [(ngModel)]="productSku.sku" #prodSkuName="ngModel">

    <div *ngIf="upsertProductSkuForm.submitted && prodSkuName.invalid" class="alert alert-danger">
      <div *ngIf="prodSkuName.errors.required">Sku name is required.</div>
      <div *ngIf="prodSkuName.errors.minlength"> Sku name must be at least 4 characters long.</div>
    </div>
  </div>

  <div class="mb-3">
    <label for="product-price" class="form-label">Price</label>
    <input id="product-price" name="product-price" class="form-control"
           type="text"
           placeholder="required"
           [(ngModel)]="productSku.priceAmount" #price="ngModel"
           appPriceValidator>
    <div *ngIf="upsertProductSkuForm.submitted && price.invalid"
         class="alert alert-danger">
      <div *ngIf="price.errors.required"> Price is required</div>
      <div *ngIf="price.errors.isInvalidPrice && !price.errors.required"> Price must be valid
      </div>
    </div>
  </div>
  <!--
  <app-img-insert [headerName]="rootProduct.name"
                  [maxSize]="maxSize"
                  [maxDimension]="maxDimension"
                  (selectedImageEmitter)="selectImage($event)">
  </app-img-insert>
  -->
  <div class="mb-3 box-part">
    <h5>Product sku detail</h5>
    <select class="form-select mb-3"
            [(ngModel)]="selectedCatalog"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="selectCatalog($event)"
            minlength="3">
      <option *ngFor="let catalog of variantCatalogs" [ngValue]="catalog">
        {{ catalog.paramName }}
      </option>
    </select>

    <div *ngIf="selectedCatalog">
      <h5>Variants </h5>
      <div class="row text-center" *ngFor="let variant of selectedCatalog?.variants">
        <div class="input-group link border-bottom mt-1">
          <div class="col-12 col-sm-11">
            <span class="d-inline-block text-white text-break">{{ variant.name }}</span>
          </div>
          <div class="col-12 col-sm-1">
            <button type="button" class="btn btn-primary btn-sm mb-1"
                    (click)="addVariant(variant, selectedCatalog)">+
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="addedVariants.length>0">
        <h5 class="mt-2">Variants added</h5>
        <div class="row text-center" *ngFor="let variant of addedVariants; index as i">
          <div class="input-group link border-bottom mt-1">
            <div class="col-12 col-sm-11">
                      <span
                        class="d-inline-block text-white text-break">{{ variant.variantName }} {{ variant.variantCatalog }}</span>
            </div>
            <div class="col-12 col-sm-1">
              <button type="button" class="btn btn-danger btn-sm mb-1"
                      (click)="removeVariant(i)">-
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ngx-spinner name="catalogSpinner"
               bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-spin-clockwise">
  </ngx-spinner>
  <ngx-spinner name="productSkuSpinner"
               bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-spin-clockwise">
  </ngx-spinner>
  <app-error [errorDto]="errorDto"></app-error>

  <div class="col-auto mb-3">
    <button type="submit" class="btn btn-primary mx-3 mx-sm-5">Add sku</button>
  </div>
</form>

<div *ngIf="productSkus?.length>0">
  <h5>Product skus </h5>
  <div class="row text-center" *ngFor="let productSku of productSkus; index as i">
    <div class="input-group link border-bottom mt-1">
      <div class="col-12 col-sm-11">
        <span class="d-inline-block text-white text-break">{{ resolveVariants(productSku) }}</span>
      </div>
      <div class="col-12 col-sm-1">
        <button type="button" class="btn btn-danger btn-sm mb-1" (click)="removeSku(i)">-
        </button>
      </div>
    </div>
  </div>
  <div class="col-auto mt-3" *ngIf="productSkus?.length>0">
    <button type="submit" class="btn btn-primary mx-3 mx-sm-5" (click)="saveProductVariants()">Save</button>
  </div>
</div>
