import {NgModule} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './home/home.component';
import {IsbnValidatorDirective} from './validator/isbn-validator.directive';
import {PriceValidatorDirective} from './validator/price-validator.directive';
import {UrlValidatorDirective} from './validator/url-validator.directive';
import {LoginComponent} from './login/login.component';
import {authInterceptorProviders} from './interceptor/auth.interceptor';
import {errorInterceptorProviders} from './interceptor/error.interceptor';
import {BackButtonDirective} from './navigation/back-button.directive';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {PasswordPatternDirective} from './validator/password-pattern.directive';
import {PasswordMatcherDirective} from './validator/password-matcher.directive';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ThemeService} from './service/themes.service';
import {NgToggleModule} from 'ng-toggle-button';
import {QrComponent} from './qr/qr.component';
import {WriteUsListComponent} from './write-us/write-us-list/write-us-list.component';
import {WriteUsComponent} from './write-us/write-us/write-us.component';
import {VariantCatalogListComponent} from './variant-catalog-list/variant-catalog-list.component';
import {UpsertVariantCatalogComponent} from './upsert-variant-catalog/upsert-variant-catalog.component';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {NgxPopperjsModule} from "ngx-popperjs";
import {GraphHomeComponent} from './graph-home/graph-home.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {SharedModule} from "./shared/shared.module";
import {OrderModule} from "./order/order.module";
import {GenericConfigModule} from "./genericconfig/generic-config.module";
import {CategoryModule} from "./category/category.module";
import {AccountModule} from "./account/account.module";
import {ProductModule} from "./product/product.module";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPopperjsModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    NgToggleModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    ReactiveFormsModule,
    NgxChartsModule,
    SharedModule,
    ProductModule,
    AccountModule,
    GenericConfigModule,
    CategoryModule,
    OrderModule,
    NgOptimizedImage
  ],
  exports: [
    CommonModule,
    FontAwesomeModule,
    NgToggleModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    PasswordMatcherDirective,
    PasswordPatternDirective,
    IsbnValidatorDirective,
    PriceValidatorDirective,
    UrlValidatorDirective,
    LoginComponent,
    BackButtonDirective,
    PasswordChangeComponent,
    ForgotPasswordComponent,
    QrComponent,
    WriteUsListComponent,
    WriteUsComponent,
    VariantCatalogListComponent,
    UpsertVariantCatalogComponent,
    GraphHomeComponent
  ],
  providers: [authInterceptorProviders, errorInterceptorProviders, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
