<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-8 align-self-center">
      <div class="box-part text-center">
          <h2 class="pb-3">Image configuration</h2>

          <div class="input-group mb-3">
            <span class="input-group-text col-12 col-sm-5 text-wrap"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Maximum size in Kb for products in eshop"
                  data-bs-customClass="custom-tooltip">File size [kb]</span>
            <input readonly name="fileSize" class="form-control" type="text"
                   [ngModel]="maxFileSize">
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text col-12 col-sm-5 text-wrap"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Maximum height and width for product"
                  data-bs-customClass="custom-tooltip">Dimension [px]</span>
            <input readonly name="fileDimension" class="form-control" type="text"
                   [ngModel]="maxDimensionSize">
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>


          <app-error [errorDto]="errorDto"></app-error>
        <button type="button" class="btn btn-danger mt-2" aria-hidden="true" appBackButton>
          <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>
