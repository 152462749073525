<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-12 align-self-center">
      <div class="box-part text-center">
        <h3 class="text-orange mb-2">Emaily</h3>

        <div class="row mb-3 justify-content-end">
          <div class="col-12 col-sm-5 col-md-4">
            <div class="input-group">
              <input type="checkbox" id="repliedId" [checked]="replied === false " (click)="filter()"/>
              <label class="checkbox-inline ms-2" aria-describedby="repliedId" id="lblrepliedId"
                     for="repliedId">Pouze nezodpovězené emaily</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="input-group d-none d-sm-inline-flex text-center">
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Email</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Přijato</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Telefon</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Odpovězeno</span>
            </div>
            <div class="col-12 col-sm-1">
              <span>
                <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
              </span>
            </div>
            <div class="col-12 col-sm-1">
              <span>
               <fa-icon class="text-darkorange" [icon]="faDelete"></fa-icon>
              </span>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row text-center" *ngFor="let email of emailListFiltered">
          <div class="input-group link">
            <div class="col-12 col-sm-4 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Email"></span>
              <span class="d-inline-block m-2 text-white">{{email.emailFrom}}</span>
            </div>
            <div class="col-12 col-sm-2 text-white border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Přijato"></span>
              <span class="d-inline-block m-2 text-white">{{email.audit.modifiedAt | date: "dd.MM.yyy HH:mm"}}</span>
            </div>
            <div class="col-12 col-sm-2 text-white border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Telefon"></span>
              <span class="d-inline-block m-2 text-white">
                <fa-icon *ngIf="!email.phoneNumber" [icon]="faNo"></fa-icon>
                {{email.phoneNumber}}
              </span>
            </div>
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Odpovězeno"></span>
              <span class="d-inline-block m-2 text-white">
                <fa-icon [icon]="resolveIconBasedOnReplied(email.replied)"></fa-icon>

              </span>
            </div>
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button class="btn btn-primary btn-sm m-2" routerLinkActive="active"
                      [routerLink]="['/email/write-us/', email.emailFrom]">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button class="btn btn-primary btn-sm m-2">
                <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <app-error [errorDto]="errorDto"></app-error>
      </div>
    </div>
  </div>
</div>
