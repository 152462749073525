<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-sm-10 align-self-center">
      <div class="box-part text-center">
        <h3>Orders</h3>

        <div class="row text-center">
          <div class="input-group d-none d-sm-inline-flex">
            <div class="col-12 col-sm-3">
              <span class="text-darkorange">Order id</span>
            </div>
            <div class="col-12 col-sm-3">
              <span class="text-darkorange">Price total</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Count of items</span>
            </div>
            <div class="col-12 col-sm-1">
              <fa-icon class="text-darkorange" [icon]="faTrash"></fa-icon>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>
        <app-error [errorDto]="errorDto"></app-error>

        <div class="row link text-center text-md-start" *ngFor="let order of orders">
          <div class="input-group border-bottom py-2">
            <div class="col-12 col-sm-3">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="orderId"></span>
              <span class="d-inline-block m-2 text-white pointer" routerLinkActive="active"
                    [routerLink]="['/orders/view/', order.orderId]"><fa-icon class="mx-2" [icon]="faEye"></fa-icon>
                {{order.orderId}}
              </span>
            </div>
            <div class="col-12 col-sm-3 text-white">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="priceTotal"></span>
              <span class="d-inline-block m-2 text-white">{{order.priceAmount}} {{order.priceCurrency}}</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="itemsCount"></span>
              <span class="d-inline-block m-2 text-white overflow">{{order.orderItems.length}}</span>
            </div>
            <div class="col-12 col-sm-1 my-auto text-center">
              <button class="btn btn-danger mt-1" (click)="removeOrder(order.orderId);">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
