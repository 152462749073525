import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  template: `
    <ul class="pagination">
      <li class="page-item" [ngClass]="{'disabled': page === 0}"><a class="page-link"
                                                                    (click)="setPageBack($event)">Previous</a>
      </li>

      <li class="page-item" *ngFor="let p of pages; let i = index"
          [ngClass]="{'active' : i === page}">
        <a class="page-link" (click)="setPage(i, $event)">{{i}}</a>
      </li>

      <li class="page-item" [ngClass]="{'disabled': page === pages.length - 1}"><a
        class="page-link" (click)="setPageForward($event)">Next</a></li>
    </ul>
  `
})
export class PaginationComponent {
  page = 0;
  @Input() pages: Array<number>;
  @Output() pageChangeEmitter: EventEmitter<number> = new EventEmitter<0>();

  setPage(i: number, event: any) {
    event.preventDefault();
    this.page = i;
    this.pageChangeEmitter.emit(this.page);
  }

  setPageBack(event: any) {
    event.preventDefault();
    if (this.page - 1 >= 0) {
      this.page = this.page - 1;
      this.pageChangeEmitter.emit(this.page);
    }
  }

  setPageForward(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.pageChangeEmitter.emit(this.page);
    }
  }
}
