import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {QrService} from '../service/qr.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ErrorDto} from '../model/error/error-dto';
import {AppConstants} from '../common/app-constants';
import {CategoryService} from '../service/category.service';
import {takeUntil} from 'rxjs/operators';
import {Category} from '../model/category/category';
import {Admin} from '../model/admin/admin';
import {AccountService} from '../service/account.service';
import {ProductService} from '../service/product.service';
import {Product} from '../model/product/product';
import {WriteUsResponse} from "../model/email/write-us-response";
import {WriteUsService} from "../service/write-us.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto = new ErrorDto();
  accountsIcon = AppConstants.iconUser;
  emailIcon = AppConstants.iconEmail;
  infoIcon = AppConstants.iconInfo;
  gearIcon = AppConstants.iconGear;
  categories = new Array<Category>();
  products = new Array<Product>();
  adminList: Array<Admin> = new Array<Admin>();
  emailsInactive = new Array<WriteUsResponse>()
  emailsAll = new Array<WriteUsResponse>()
  errorCategory = new ErrorDto();
  errorAdmin = new ErrorDto();
  errorProducts = new ErrorDto();
  errorConfig = new ErrorDto();
  errorEmails = new ErrorDto();
  info = AppConstants.iconInfo;
  emailPercentage = 0;

  constructor(private qrService: QrService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private categoryService: CategoryService,
              private accountService: AccountService,
              private productService: ProductService,
              private writeUsService: WriteUsService) {
  }

  ngOnInit() {
    this.spinner.show().then(() =>
      forkJoin([
        this.loadCategories(),
        this.loadAccounts(),
        this.loadProducts(),
        this.loadEmails(0, false)
      ])).then(() => this.spinner.hide());
  }

  private loadProducts() {
    return this.productService.listProducts(0, this.errorProducts)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.products = response.data;
        },
      });
  }
  private loadEmails(pageNumber: number, replied: boolean) {
    return this.writeUsService.loadEmailsWriteUs(this.errorEmails, pageNumber, 20, null, replied)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emailsAll = response.data;
          this.emailsInactive = response.data.filter(res => res.replied == false);
        },
      });
  }
  private loadCategories() {
    return this.categoryService.getCategories(this.errorCategory)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.categories = response.data;
        },
      });
  }

  private loadAccounts() {
    return this.accountService.getAccounts(this.errorAdmin)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.adminList = response.data;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
