<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-9 col-md-12 align-self-center">
      <div class="box-part text-center">
        <h3 class="text-orange mb-3">Generic config admin</h3>
        <!--
        <div class="row">
          <app-generic-config-list-filter
            (selectedModuleEvent)="filterChanged($event)"></app-generic-config-list-filter>
        </div>
        -->
        <div class="row">
          <div class="input-group d-none d-sm-inline-flex">
            <div class="col-12 col-sm-2">
              <span class="text-darkorange text-center">Module</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Name</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Type</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="text-darkorange">Value</span>
            </div>
            <div class="col-12 col-sm-1">
              <span class="text-darkorange"></span>
            </div>
            <div class="col-12 col-sm-1">
              <span class="text-darkorange"></span>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row border-bottom link text-center text-md-start" *ngFor="let config of genericConfigs">
          <div class="input-group">
            <div class="col-12 col-sm-2">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Module"></span>
              <span class="d-inline-block m-2 text-white text-break">{{config.key.module}}</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
              <span class="d-inline-block m-2 text-white text-break">{{config.key.name}}</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Type"></span>
              <span class="d-inline-block m-2 text-white">{{config.type}}</span>
            </div>
            <div class="col-12 col-sm-2">
              <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Value"></span>
              <span class="d-inline-block m-2 text-white text-break">{{config.value}}</span>
            </div>
            <div class="col-12 col-sm-1">
              <button class="btn btn-primary btn-sm m-2"
                      routerLinkActive="active"
                      [routerLink]="['/generic-configs/edit/', config.key.module, config.key.name]">
                <fa-icon [icon]="faEdit"></fa-icon>

              </button>
            </div>
            <div class="col-12 col-sm-1">
              <button class="btn btn-primary btn-sm m-2">
              <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <app-error [errorDto]="errorDto"></app-error>
        <button class="btn btn-primary" routerLinkActive="active" routerLink="/generic-configs/create">Create config
        </button>
      </div>
    </div>
  </div>
</div>
