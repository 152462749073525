import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderListComponent} from "./order-list/order-list.component";
import {OrderViewComponent} from "./order-view/order-view.component";
import {SharedModule} from "../shared/shared.module";
import {AddressViewComponent} from "./address-view/address-view.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {RouterLink} from "@angular/router";
import {NgxSpinnerModule} from "ngx-spinner";


@NgModule({
  declarations: [
    OrderListComponent,
    OrderViewComponent,
    AddressViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FaIconComponent,
    RouterLink,
    NgxSpinnerModule
  ],
  exports: []
})
export class OrderModule {
}
