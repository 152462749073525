import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {Router} from '@angular/router';
import {AppConstants} from '../common/app-constants';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ErrorDto} from '../model/error/error-dto';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SharedImgService} from '../service/shared-img.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  form: any = {};
  errorDto: ErrorDto = new ErrorDto();
  faUser = AppConstants.iconUser;
  faLock = AppConstants.iconLock;
  imgLoginPath: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private sharedService: SharedImgService,
    private authService: AuthService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
/*
    if (this.authService.currentAdminValue) {
      this.route.navigate(['/home']);
    }*/
  }

  ngOnInit(): void {
    this.sharedService.currentImgPath.subscribe(data => this.imgLoginPath = data);
  }

  login(): void {
    this.spinner.show().then(r => r);
    this.authService.login(this.form, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.route.navigate(['/home'])
                .then(() => this.toastr.success('200 Welcome', 'Logged in'));
            }
          );

        },
        error: () => {
          this.spinner.hide()
            .then(() => this.toastr.error(this.errorDto.httpStatus + ' login failed', 'Login'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
