<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-10 align-self-center">
      <div class="box-part text-center">
        <ngx-spinner name="productImageSpinner"
                     bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <ngx-spinner name="productSpinner"
                     bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>
        <ngx-spinner name="productSkuSpinner"
                     bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <h2 class="border-bottom pb-2">Product&nbsp;detail</h2>
        <h3 class="m-3">{{selectedProduct.name}}</h3>
        <app-audit [audit]="selectedAudit"></app-audit>
        <h4 class="m-3">Categories</h4>
        <div class="content mt-1">
          <div class="row text-white mb-3" *ngFor ="let cat of selectedProduct.categoryUrls">
            <div class="col">
              <div class="pointer"
                   [routerLink]="['/categories/view/', cat]">
                <fa-icon class="url" [icon]="faEye"></fa-icon>&nbsp;{{cat}}
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center content">
          <div class="col-xs-12 col-md-8">
            <span *ngIf="!selectedProduct.description"><fa-icon [icon]="faThrash"></fa-icon>&nbsp;&nbsp;Empty description!!</span>
            <div *ngIf="selectedProduct.description">
              <div [innerHTML]="selectedProduct.description"></div>
            </div>
          </div>
        </div>
        <app-img-view
                      [imgName]="selectedProduct?.name"
                      [existingImage]="existingImage"
                      [imgContentType]="imgContentType"
        ></app-img-view>
        <app-error [errorDto]="errorDto"></app-error>
        <app-error [errorDto]="errorProductSkuDto"></app-error>

        <div *ngIf="productSkus?.length >0">
          <div class="row link text-center text-md-start">
            <div class="input-group d-none d-sm-inline-flex">
              <div class="col-12 col-sm-4">
                <span class="text-darkorange text-center">Name</span>
              </div>
              <div class="col-12 col-sm-3">
                <span class="text-darkorange">Sku</span>
              </div>
              <div class="col-12 col-sm-3">
                <span class="text-darkorange">Price</span>
              </div>
              <div class="col-12 col-sm-1 text-center">
                <span class="text-darkorange"><fa-icon class="text-darkorange" [icon]="faEye"></fa-icon></span>
              </div>
              <div class="col-12 col-sm-1 text-center">
                <span class="text-darkorange"><fa-icon class="text-darkorange" [icon]="faTrash"></fa-icon></span>
              </div>
            </div>
          </div>

          <div class="row link text-center text-md-start" *ngFor="let productSku of productSkus">
            <div class="input-group border-bottom py-2">
              <div class="col-12 col-sm-4">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
                <span class="pointer d-inline-block m-2 text-white text-break">{{productSku.name}}</span>
              </div>
              <div class="col-12 col-sm-3">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Sku"></span>
                <span class="d-inline-block m-2 text-white">{{productSku.sku}}</span>
              </div>
              <div class="col-12 col-sm-3">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Price"></span>
                <span class="d-inline-block m-2 text-white">{{productSku.priceAmount}}</span>
              </div>
              <div class="col-12 col-sm-1 text-center my-auto">
                <button class="btn btn-primary"
                        routerLinkActive="active"
                        [routerLink]="['/products/sku/view/', productSku.sku]">
                  <fa-icon [icon]="faEye"></fa-icon>
                </button>
              </div>
              <div class="col-12 col-sm-1 text-center my-auto">
                <button (click)="removeProductSku(productSku)" class="btn btn-danger"
                        routerLinkActive="active">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mt-3">
          <button type="button" class="btn btn-danger me-2" aria-hidden="true" appBackButton>
            <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
          </button>
          <button type="button" class="btn btn-primary ms-2" routerLinkActive="active"
                  [routerLink]="['/products/sku/', selectedProductOriginalUrl]">
            <fa-icon [icon]="faPlus"></fa-icon>
            Add&nbsp;sku
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
