<div class="container">
  <h2>Audit</h2>
  <div class="row text-center text-sm-start">
    <div class="col">
      <span class="d-block d-sm-inline-flex badge"><span class="customize-short" data-suffix=":">
        <fa-icon [icon]="faCalendar" class="pointer-base me-2"></fa-icon>Created&nbsp;at</span>
        <span class="mx-auto ms-sm-2 d-block d-sm-inline-flex customize-long p-2 p-sm-0">{{audit?.createdAt | date: 'dd-MM-yyyy hh:mm:ss'}}</span></span>
    </div>
  </div>
  <div class="row text-center text-sm-start">
    <div class="col">
      <span class="d-block d-sm-inline-flex badge"><span class="customize-short" data-suffix=":">
          <fa-icon [icon]="faUser" class="pointer-base me-2"></fa-icon>Created&nbsp;by</span>
        <span class="mx-auto ms-sm-2 d-block d-sm-inline-flex customize-long p-2 p-sm-0" >{{audit?.createdBy}}</span></span>
    </div>
  </div>
  <div class="row text-center text-sm-start">
    <div class="col">
      <span class="d-block d-sm-inline-flex badge"><span class="customize-short" data-suffix=":">
        <fa-icon [icon]="faCalendar" class="pointer-base me-2"></fa-icon>Modified&nbsp;at</span>
        <span class="mx-auto ms-sm-2 d-block d-sm-inline-flex customize-long p-2 p-sm-0">{{audit?.modifiedAt | date: 'dd-MM-yyyy hh:mm:ss'}}</span></span>
    </div>
  </div>
  <div class="row text-center text-sm-start">
    <div class="col">
      <span class="d-block d-sm-inline-flex badge"><span class="customize-short" data-suffix=":">
          <fa-icon [icon]="faUser" class="pointer-base me-2"></fa-icon>Modified&nbsp;by</span>
        <span class="mx-auto ms-sm-2 d-block d-sm-inline-flex customize-long p-2 p-sm-0">{{audit?.modifiedBy}}</span></span>
    </div>
  </div>
</div>
