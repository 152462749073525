import {Component, OnDestroy, OnInit} from '@angular/core';

import {BehaviorSubject, Subject} from "rxjs";

import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import {ProductSku} from "../../model/product-sku/product-sku";
import {Audit} from "../../model/audit/audit";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {ProductSkuService} from "../../service/product-sku.service";


@Component({
  selector: 'app-sku-view',
  templateUrl: './product-sku-view.component.html'
})
export class ProductSkuViewComponent implements OnInit, OnDestroy {
  productSku: ProductSku = new ProductSku();
  audit: Audit = new Audit();
  faEdit = AppConstants.iconEdit;
  errorDto: ErrorDto = new ErrorDto();
  faArrowLeft = AppConstants.iconArrowLeft;

  destroy$: Subject<boolean> = new Subject<boolean>();
  imgContentType: string;
  existingImage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(protected productSkuService: ProductSkuService,
              protected route: Router,
              protected actRoute: ActivatedRoute,
              protected spinner: NgxSpinnerService,
              protected toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.productSku.sku = params.get('sku');
      this.loadSku();
    });
  }

  loadSku() {
    this.spinner.show('productSkuSpinner').then(r => r);
    console.log("")
    return this.productSkuService.getProductSku(this.productSku.sku, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: skuResponse => {
          this.spinner.hide('productSkuSpinner').then(
            () => {
              this.productSku = skuResponse.data;
              this.audit = skuResponse.audit;
              this.imgContentType = skuResponse.data.file.type;
              this.existingImage.next('data:image/jpeg;base64,' + skuResponse.data.file.bytes);
              this.errorDto = new ErrorDto();
            });
        },
        error: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' product skus failed', 'Product')
          );
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
