<div class="row m-3 pt-3">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-spin-clockwise">
  </ngx-spinner>

  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card card-stats m-2">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title">
              Účty
            </h5>
            <app-error [errorDto]="errorAdmin"></app-error>
            <h2 class="mt-2 text-white">{{ adminList.length }}</h2>
          </div>
          <div class="col-auto my-auto">
            <fa-icon [icon]="accountsIcon" class="h1 pointer"></fa-icon>
          </div>
        </div>
      </div>
      <div class="small-box-footer">
        <a href="#" [routerLink]="['/accounts']" class="text-darkorange">Více informací
          <fa-icon [icon]="infoIcon" class="ms-1"></fa-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card card-stats m-2">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title">
              Emaily
            </h5>
            <app-error [errorDto]="errorEmails"></app-error>
            <h2 class="mt-2 text-white">{{ emailsInactive?.length }} / {{ emailsAll?.length }}</h2>
          </div>
          <div class="col-auto my-auto">
            <fa-icon [icon]="emailIcon" class="h1 pointer"></fa-icon>
          </div>
        </div>
      </div>
      <div class="small-box-footer">
        <a href="#" [routerLink]="['/email/write-us']" class="text-darkorange">Více informací
          <fa-icon [icon]="infoIcon" class="ms-1"></fa-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card card-stats m-2">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title">
              Kategorie
            </h5>
            <app-error [errorDto]="errorCategory"></app-error>
            <h2 class="mt-2 text-white">{{categories.length}}</h2>
          </div>
          <div class="col-auto my-auto">
            <fa-icon [icon]="gearIcon" class="h1 pointer"></fa-icon>
          </div>
        </div>
      </div>
      <div class="small-box-footer">
        <a href="#" [routerLink]="['/categories']" class="text-darkorange">Více informací
          <fa-icon [icon]="infoIcon" class="ms-1"></fa-icon></a>
      </div>
    </div>
  </div>


  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card card-stats m-2">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title">
              Produkty
            </h5>
            <app-error [errorDto]="errorProducts"></app-error>
            <h2 class="mt-2 text-white">{{products.length}}</h2>
          </div>
          <div class="col-auto my-auto">
            <fa-icon [icon]="gearIcon" class="h1 pointer"></fa-icon>
          </div>
        </div>
      </div>
      <div class="small-box-footer">
        <a href="#" [routerLink]="['/products']" class="text-darkorange">Více informací
          <fa-icon [icon]="infoIcon" class="ms-1"></fa-icon></a>
      </div>
    </div>
  </div>

</div>
