import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from "../../service/order.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from "@angular/router";
import {ErrorDto} from "../../model/error/error-dto";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {OrderDetailOutputDto} from "../../model/order/order-detail-output-dto";
import {ToastrService} from "ngx-toastr";
import {AppConstants} from "../../common/app-constants";
import {Audit} from "../../model/audit/audit";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html'
})
export class OrderViewComponent implements OnInit, OnDestroy {

  selectedOrderId: string;
  errorDto = new ErrorDto();
  destroy$: Subject<boolean> = new Subject<boolean>();
  faView = AppConstants.iconEye;

  actualOrder: OrderDetailOutputDto = new OrderDetailOutputDto();
  actualAudit: Audit = new Audit()


  constructor(private orderService: OrderService,
              private spinner: NgxSpinnerService,
              private actRoute: ActivatedRoute,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.spinner.show().then(r => r);
    this.actRoute.paramMap.subscribe(params => {
      this.selectedOrderId = params.get('orderId');
    });
    this.orderService.getOrderDetail(this.selectedOrderId, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: orderResponse => {
          this.spinner.hide().then(() => this.toastr.info('OrderId ' + this.selectedOrderId + ' loaded', 'Order'));
          this.actualOrder = orderResponse.data;
          this.actualAudit = orderResponse.audit
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto + ' failed', 'Order'));
          this.actualOrder = new OrderDetailOutputDto();
          this.actualAudit = new Audit()
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected readonly faArrowLeft = faArrowLeft;
}
