<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-8 align-self-center">
      <div class="box-part text-center">
        <form #upsertCatalogVariantForm="ngForm" novalidate
              (ngSubmit)="upsertCatalogVariantForm.valid  &&upsertCatalogVariantForm.dirty && upsertCatalogVariant()">
          <h2>{{operation}} category variant</h2>

          <div class="mb-3">
            <label for="category-name" class="form-label">Name</label>
            <input id="category-name" name="catName" class="form-control" type="text"
                   placeholder="required"
                   required
                   minlength="4"
                   maxlength="100"
                   [(ngModel)]="variantCatalog.name" #catName="ngModel">

            <div *ngIf="upsertCatalogVariantForm.submitted && catName.invalid" class="alert alert-danger">
              <div *ngIf="catName.errors.required">Name is required.</div>
              <div *ngIf="catName.errors.minlength">Name must be at least 4 characters long.</div>
              <div *ngIf="catName.errors.maxlength">Name must be at maximum 100 characters long.</div>
            </div>
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="mb-4">
            <label for="category-param-name" class="form-label">Param Name</label>
            <input id="category-param-name" name="paramName" class="form-control" type="text"
                   placeholder="required"
                   required
                   minlength="4"
                   maxlength="24"
                   [(ngModel)]="variantCatalog.paramName" #paramName="ngModel">
            <div
              *ngIf="upsertCatalogVariantForm.submitted && paramName.invalid && (paramName.dirty || paramName.touched)"
              class="alert alert-danger">
              <div *ngIf="paramName.errors.required">Param Name is required.</div>
              <div *ngIf="paramName.errors.minlength">Param Name must be at least 4 characters long.</div>
              <div *ngIf="paramName.errors.maxlength">Param Name must be at maximum 24 characters long.</div>
            </div>
          </div>

          <app-error [errorDto]="errorDto"></app-error>

          <div class="col-auto mb-4">
            <button type="submit" class="btn btn-primary mx-3 mx-sm-5">{{operation}}</button>
            <button type="button" class="btn btn-danger mx-3 mx-sm-5" appBackButton>
              <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
            </button>
          </div>
        </form>

        <div *ngIf="variantCatalog?.variants?.length>0" class="box-part section m-auto">
          <h5>Variant param names</h5>
          <div class="row text-center text-sm-center" *ngFor="let variant of variantCatalog.variants; let i = index">
            <div class="input-group link border-bottom">
              <div class="col-sm-10 col">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
                <span class="d-inline-block m-2 text-white">{{variant.name}}</span>
                <button *ngIf="i>0" (click)="up(variant,i)"
                        class="btn btn-primary btn-sm m-2 d-sm-none">
                  <fa-icon [icon]="faArrowUp"></fa-icon>
                </button>
                <button *ngIf="i +1< variantCatalog?.variants.length" (click)="down(variant, i)"
                        class="btn btn-primary btn-sm m-2 d-sm-none">
                  <fa-icon [icon]="faArrowDown"></fa-icon>
                </button>
                <button (click)="deleteVariant(variant)" class="btn btn-danger btn-sm m-2 d-sm-none">
                  <fa-icon [icon]="faDelete"></fa-icon>
                </button>
                <div class="btn-group-vertical d-sm-inline-flex d-none float-end m-2">
                  <button *ngIf="i>0" (click)="up(variant, i)"
                          class="btn btn-primary btn btn-danger btn-sm">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </button>
                  <button *ngIf="i+1 < variantCatalog?.variants.length" (click)="down(variant, i)"
                          class="btn btn-primary btn btn-danger btn-sm">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="col-sm-2 d-sm-inline-flex d-none">
                <button (click)="deleteVariant(variant)" class="btn btn-danger btn-sm m-2">
                  <fa-icon [icon]="faDelete"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <button *ngIf="visible" (click)="upsertArrangement()" class="btn btn-primary mt-2 mx-3 mx-sm-5">Update
          </button>
        </div>

        <button type="button" class="btn btn-primary mx-3 mx-sm-5 mt-4 mb-3" (click)="addVariant()"
                *ngIf="this.route.url !== '/variant-catalogs/create'">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>

        <form [formGroup]="form" #ngForm="ngForm" class="mt-1 section m-auto"
              *ngIf="this.route.url !== '/variant-catalogs/create'"
              (ngSubmit)="form.valid && addVariants()">
          <div formArrayName="variant">
            <div
              *ngFor="let variantGroups of variant?.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="input-group mb-2">
                <input class=" form-control" type="text" formControlName="name" placeholder="variant name"
                       maxlength="24" required/>
                <button type="submit" class="btn btn-primary" (click)="removeAddedVariant(i)">
                  <fa-icon [icon]="faDelete"></fa-icon>
                </button>
              </div>

              <div *ngIf="ngForm.submitted && variantGroups.invalid" class="alert alert-danger">
                Variant is invalid (1-24 length)
              </div>
            </div>
          </div>
          <button *ngIf="variant?.controls?.length>0" class="btn btn-primary mx-3 mx-sm-5 mt-4 mb-3">Add variant
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

