import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorDto} from '../model/error/error-dto';
import {AppConstants} from '../common/app-constants';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountService} from '../service/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SharedImgService} from "../service/shared-img.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  errorDto: ErrorDto = new ErrorDto();
  faUser = AppConstants.iconUser;
  email: string;
  currentImgPath:string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private accountService: AccountService,
              private sharedImgService: SharedImgService,
              private route: Router,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.sharedImgService.currentImgPath.subscribe(data => this.currentImgPath = data);
  }

  forgotPassword() {
    this.spinner.show('refreshPasswordSpinner').then(r => r);
    this.accountService.passwordRecovery(this.email, this.errorDto,)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.success('Email send', 'Activation');
              this.route.navigate(['/login']).then(r => r);
            }
          );

        },
        error: () => {
          this.toastr.error(this.errorDto.httpStatus + ' sending email failed', 'Login');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
