import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricePipe} from "./price.pipe";
import {ErrorComponent} from "./error.component";
import {InfoComponent} from "./app-info.component";
import {AuditComponent} from "./audit/audit.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {PaginationComponent} from "./pagination/pagination.component";


@NgModule({
  declarations: [
    PricePipe,
    ErrorComponent,
    InfoComponent,
    AuditComponent,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    FaIconComponent
  ],
  exports: [
    PricePipe,
    ErrorComponent,
    InfoComponent,
    AuditComponent,
    PaginationComponent
  ]
})
export class SharedModule {
}
