<div class="box">
  <div class="row justify-content-center box-part">
    <div class="col-lg-5 col-sm-12">
      <div class="text-center">
        <h3 class="text-orange mb-3">Update product</h3>
        <form #editProductForm="ngForm" novalidate
              (ngSubmit)="editProductForm.form.valid && upsertProduct()">

          <ngx-spinner name="categoriesSpinnerEdited"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
          <ngx-spinner name="productImageSpinnerEdited"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
          <ngx-spinner name="productSpinnerEdited"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
          <ngx-spinner name="catalogSpinner"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <hr>
          <h4>{{selectedProduct.name}}</h4>
          <div class=content>
            <div class="m-2">
              <h5>Category</h5>
              <select class="form-select mb-3"
                      [(ngModel)]="selectedCategory"
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="selectCategory($event)"
                      #select="ngModel"
                      minlength="3">
                <option *ngFor="let category of categories" [ngValue]="category">
                  {{category.url}}
                </option>
              </select>
            </div>

            <div *ngIf="!selectedCategory.url" class="alert alert-danger"> Category must be selected.</div>
            <div *ngIf="editProductForm.submitted && select.invalid"
                 class="alert alert-danger">
              <div *ngIf="select.errors.required">
                Category must be selected.
              </div>
              <div *ngIf="select.errors.minlength">
                Category must be at least 3 characters long.
              </div>
            </div>
            <ul class="list-unstyled text-start m-2">
              <li class="pointer m-2" aria-hidden="true" routerLinkActive="active"
                  [routerLink]="['/categories/view/',selectedCategory.url]">
                <fa-icon class="text-darkorange" [icon]="faEye"></fa-icon>&nbsp;&nbsp;
                {{selectedCategory.url}}
              </li>
              <li class="m-2">
                <fa-icon class="text-darkorange" [icon]="faAddressCard"></fa-icon>&nbsp;&nbsp;{{selectedCategory.name}}
              </li>
              <li *ngIf="!selectedCategory.description">
                <fa-icon class="text-darkorange" [icon]="faThrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty description!!
              </li>
              <li class="m-2" *ngIf="selectedCategory.description">
                <fa-icon class="text-darkorange"
                         [icon]="faBars"></fa-icon>&nbsp;&nbsp;&nbsp;{{selectedCategory.description}}</li>
            </ul>
            <button type="button" class="btn btn-primary" (click)="addCategory()">&nbsp;Add category</button>
          </div>

          <h5>Categories</h5>
          <div class="row link text-center" *ngFor="let url of addedCategories">
            <div class="input-group border-bottom py-2">
              <div class="col-12 col-sm-10">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
                <span class="pointer d-inline-block m-2 text-white text-break">{{url}}</span>
              </div>
              <div class="col-12 col-sm-1 text-center my-auto">
                <button type="button" (click)="removeCategories(url)" class="btn btn-danger">
                  <fa-icon [icon]="faThrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <app-img-insert [headerName]="headerName"
                   [maxDimension]="maxDimension"
                   [maxSize]="maxSize"
                   [existingImage]="existingImage"
                   [existingImageName]="selectedProduct.name"
                   (selectedImageEmitter)="selectImage($event)"
          ></app-img-insert>

          <div class="content">
            <h4 class="mt-3">Product detail</h4>
            <div class="m-2">
              <label for="product-name" class="form-label">Name</label>
              <input id="product-name" name="name" class="form-control" type="text"
                     placeholder="required"
                     minlength="4"
                     [(ngModel)]="selectedProduct.name" #name="ngModel">
              <div *ngIf="editProductForm.submitted && name.invalid"
                   class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
                <div *ngIf="name.errors.minlength">
                  Name must be at least 4 characters long.
                </div>
              </div>
            </div>
            <div class="m-2">
              <label for="product-url" class="form-label">Url</label>
              <input id="product-url" name="url" class="form-control" type="text"
                     placeholder="required"
                     appUrlValidator
                     [(ngModel)]="selectedProduct.url" #url="ngModel">
              <div *ngIf="editProductForm.submitted && url.invalid"
                   class="alert alert-danger">
                <div *ngIf="url.errors.required">Url is required.</div>
                <div *ngIf="url.errors.isInvalidUrl && !url.errors.required"> Url must be valid</div>
              </div>
            </div>

            <div class="m-2">
              <label for="product-isbn" class="form-label">ISBN</label>
              <input id="product-isbn" name="isbn" class="form-control" type="text"
                     placeholder="required (9971502100)"
                     [(ngModel)]="selectedProduct.isbn" #isbn="ngModel"
                     appIsbnValidator>

              <div *ngIf="editProductForm.submitted &&isbn.invalid"
                   class="alert alert-danger">
                <div *ngIf="isbn.errors.required"> ISBN is required</div>
                <div *ngIf="isbn.errors.isInvalidIsbn && !isbn.errors.required"> ISBN must be valid</div>
              </div>
            </div>

            <div class="m-2">
              <label for="product-price" class="form-label">Price</label>
              <input id="product-price" name="product-price" class="form-control"
                     type="text"
                     placeholder="required"
                     [(ngModel)]="selectedProduct.priceAmount" #price="ngModel"
                     appPriceValidator>
              <div *ngIf="editProductForm.submitted && price.invalid"
                   class="alert alert-danger">
                <div *ngIf="price.errors.required"> Price is required</div>
                <div *ngIf="price.errors.isInvalidPrice && !price.errors.required"> Price must
                  be valid
                </div>
              </div>
            </div>

            <div class="m-2">
              <label for="product-currency" class="form-label">Currency</label>
              <select id="product-currency" class="form-select mb-3"
                      [(ngModel)]="selectedProduct.priceCurrency"
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="selectCurrency($event)"
                      #currency="ngModel"
                      minlength="3">
                <option *ngFor="let currency of currencies" [ngValue]="currency">
                  {{currency}}
                </option>
              </select>
              <div *ngIf="editProductForm.submitted && currency.invalid"
                   class="alert alert-danger">
                <div *ngIf="currency.errors.required">
                  Currency is required.
                </div>
                <div *ngIf="currency.errors.minlength">
                  Currency must be at least 3 characters long.
                </div>
              </div>
            </div>

            <div class="m-2">
              <label for="product-edited-description" class="form-label">Description</label>
              <angular-editor id="product-edited-description" name="description" [placeholder]="'Enter text here...'"
                              [(ngModel)]="htmlContent" [config]="editorConfig" class="form-control">

              </angular-editor>

            </div>

            <app-error [errorDto]="imgErrorDto"></app-error>
            <app-error [errorDto]="errorDto"></app-error>

            <div class="col-auto">
              <button class="btn btn-primary" type="submit">&nbsp;Submit</button>
              <button type="button" class="ms-5 btn btn-danger" appBackButton>
                <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
              </button>
            </div>

            <div *ngIf="selectedProduct?.url" class="col-auto mt-3">
              <button type="button" class="btn btn-primary ms-2" routerLinkActive="active"
                       (click)="show()">
                <fa-icon [icon]="faPlus"></fa-icon>
                Add&nbsp;sku
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-1 col-sm-12"></div>
    <div class="col-lg-5 col-sm-12 text-center justify-content-end" *ngIf="shown">
      <app-product-sku [selectedProductRootUrl]="selectedProductOriginalUrl"
                       [rootProduct]="selectedProduct"
      ></app-product-sku>
    </div>

  </div>
</div>
