<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-8 align-self-center">
      <div class="box-part text-center text-white">
        <h2>Advertisement Configuration</h2>
        <hr>
        <h3 class="text-white">{{advertisement?.eshopName}}</h3>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row">
          <div class="col pt-4">
            <span class="text-darkorange d-block">Email eshopu pro reklamaci:</span>
            <span class="fw-bold" *ngIf="!advertisement.eshopEmail"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.eshopEmail">
              {{advertisement.eshopEmail}}
            </div>
          </div>

          <div class="col pt-4">
            <span class="text-darkorange d-block">Email na majitele eshopu:</span>
            <span class="fw-bold" *ngIf="!advertisement.emailWho"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.emailWho">
              {{advertisement.emailWho}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">Internetový obchod, který je provozován na webové adrese:</span>
            <span class="fw-bold" *ngIf="!advertisement.webEshopAddress"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.webEshopAddress">
              {{advertisement.webEshopAddress}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">Mezi kupujícím a prodávajícím v oblasti prodeje:</span>
            <span class="fw-bold" *ngIf="!advertisement.sortiment"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.sortiment">
              {{advertisement.sortiment}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block"> Souvisejícího zboží mezi společností :</span>
            <span class="fw-bold" *ngIf="!advertisement.eshopName"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.eshopName">
              {{advertisement.eshopName}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block"> Prodávajícím je společnost {{advertisement.eshopName}}
              , se sídlem :</span>
            <span class="fw-bold" *ngIf="!advertisement.address"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.address">
              {{advertisement.address}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">IČO:</span>
            <span class="fw-bold" *ngIf="!advertisement.ico"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.ico">
              {{advertisement.ico}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block"> Kontaktní údaje na prodávajícího jsou:</span>
            <span class="fw-bold" *ngIf="!advertisement.phone"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty phone!!</span>
            <span class="fw-bold" *ngIf="!advertisement.emailWho"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty email!!</span>
            <div class="fw-bold" *ngIf="advertisement.phone || advertisement?.emailWho">
              <span class="d-block">{{advertisement.phone}}</span>
              <span class="d-block"> {{advertisement.emailWho}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block"> Při nákupu zboží nad:</span>
            <span class="trash fw-bold" *ngIf="!advertisement.limitFreeShippingPrice"><fa-icon
              [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="info fw-bold" *ngIf="advertisement.limitFreeShippingPrice">
              {{advertisement.limitFreeShippingPrice}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block"> Mezi Prodávajícím a Kupujícím s platností od:</span>
            <span class="trash fw-bold" *ngIf="!advertisement.dueDate"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="info fw-bold" *ngIf="advertisement.dueDate">
              {{advertisement.dueDate}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">Tento reklamační řád se vztahuje na prodej zboží:</span>
            <span class="trash fw-bold" *ngIf="!advertisement.who"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="info fw-bold" *ngIf="advertisement.who">
              {{advertisement.who}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">Záruční lhůta pro:</span>
            <span class="fw-bold" *ngIf="!advertisement.consumer"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.consumer">
              {{advertisement.consumer}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 pt-4">
          <span
            class="text-darkorange d-block">Prodávající je povinen o reklamaci rozhodnout ihned, ve složitějších případech do:</span>
            <span class="fw-bold" *ngIf="!advertisement.complaintDays"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="fw-bold" *ngIf="advertisement.complaintDays">
              {{advertisement?.complaintDays}}
            </div>
          </div>

          <div class="col-12 col-sm-6 pt-4">
            <span class="text-darkorange d-block">Nejpozději do:</span>
            <span class="trash fw-bold" *ngIf="!advertisement.complaintDaysDelay"><fa-icon [icon]="faTrash"></fa-icon>&nbsp;&nbsp;&nbsp;Empty!!</span>
            <div class="info fw-bold" *ngIf="advertisement.complaintDaysDelay">
              {{advertisement?.complaintDaysDelay}}
            </div>
          </div>
        </div>

        <app-error [errorDto]="errorDto"></app-error>

        <button type="button" class="btn btn-danger m-5" aria-hidden="true" appBackButton>
          <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>
