import {AppConstants} from '../app-constants';
import {takeUntil} from 'rxjs/operators';
import {ProductService} from '../../service/product.service';
import {Subject} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ErrorDto} from '../../model/error/error-dto';
import {GenericConfigService} from "../../service/generic-config.service";
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import ModuleEnum = GenericConfigKeyDto.ModuleEnum;

export abstract class BaseImageComponent {
  public selectedFile: File;
  public imgErrorDto: ErrorDto = new ErrorDto();
  public maxSize = 0;
  public maxDimension = 0;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  protected constructor(protected spinner: NgxSpinnerService,
                        protected toastr: ToastrService,
                        protected router: Router,
                        protected productService: ProductService,
                        protected genericConfigService: GenericConfigService,
                        protected call: boolean) {
    if (call) {
      this.getConfiguration();
    }
  }

  protected upsertProductImage(productUrl: string, errorDto: ErrorDto) {
    if (this.selectedFile == null || productUrl == null) {
      this.toastr.success('Product updated');
      return;
    }
    this.spinner.show().then(r => r);
    this.productService.updateProductImage(this.selectedFile, productUrl, errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(() => {
            this.selectedFile = null;
            this.toastr.success(productUrl + 'Product with image updated', 'Product');
            setTimeout(() => {
              this.router.navigate(['/products']).then(r => r);
            }, AppConstants.toastSuccessDelay);
          });
        },
        error: () => {
          this.spinner.hide().then(() => {
            this.toastr.error(errorDto.httpStatus + 'image upsert failed', 'Product');
          });
        }
      });

  }

  public getConfiguration() {
    this.genericConfigService.getGenericConfigs(ModuleEnum.FRONTEND_ADMIN,this.imgErrorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: configurationDto => {
          console.log('Configuration GET called successful', this.maxSize, this.maxDimension);
          this.maxSize = Number(configurationDto.data.filter(data => data.key.name =="IMG_SIZE")[0].value);
          this.maxDimension = Number(configurationDto.data.filter(data => data.key.name =="IMG_DIMENSION")[0].value)
          this.imgErrorDto = new ErrorDto();
          this.toastr.info(this.maxSize + ' x ' + this.maxDimension + '  maxSize x Dimension set', 'Product');
        },
        error: () => {
          this.toastr.error('Get configuration for image failed', 'Product');
        }
      });
  }
}
