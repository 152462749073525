<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-sm-7 align-self-center">
      <div class="box-part text-center">
        <form #editBankForm="ngForm" novalidate (ngSubmit)="editBankForm.valid && upsertIban()">
          <h2 class="pb-3">Bank configuration</h2>
          <div class="input-group mb-3">
            <span class="input-group-text"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="CZ bank IBAN"
                  data-bs-customClass="custom-tooltip">Iban</span>
            <input name="ibanName" class="form-control" type="text" required
                   [(ngModel)]="bankConfigurationDto.iban" #ibanName="ngModel">
            <button type="submit" class="btn btn-primary">Update</button>
          </div>
          <div *ngIf="editBankForm.submitted && ibanName.invalid" class="alert alert-danger">
            <div *ngIf="ibanName.errors.required">Iban is required.</div>
          </div>
          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
        </form>

        <app-error [errorDto]="errorDto"></app-error>

      </div>
    </div>
  </div>
</div>
