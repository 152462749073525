/**
 * Eshop platform core
 * Walderkova a Dejvova servica
 *
 * OpenAPI spec version: 1.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GenericConfigKeyDto {
    module?: GenericConfigKeyDto.ModuleEnum;
    name?: string;
}
export namespace GenericConfigKeyDto {
    export type ModuleEnum = 'FRONTEND_ADMIN' | 'FRONTEND_CUSTOMER' | 'TEXT_CONTENT' | 'CUSTOM';
    export const ModuleEnum = {
        FRONTEND_ADMIN: 'FRONTEND_ADMIN' as ModuleEnum,
        FRONTEND_CUSTOMER: 'FRONTEND_CUSTOMER' as ModuleEnum,
        TEXT_CONTENT: 'TEXT_CONTENT' as ModuleEnum,
        CUSTOM: 'CUSTOM' as ModuleEnum
    };
}
