import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryViewComponent} from "./category-view/category-view.component";
import {CategoryUpsertComponent} from "./category-upsert/category-upsert.component";
import {CategoryListComponent} from "./category-list/category-list.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {SharedModule} from "../shared/shared.module";
import {RouterLink} from "@angular/router";
import {NgxSpinnerModule} from "ngx-spinner";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    CategoryListComponent,
    CategoryUpsertComponent,
    CategoryViewComponent,
  ],
  imports: [
    CommonModule,
    FaIconComponent,
    SharedModule,
    RouterLink,
    NgxSpinnerModule,
    FormsModule
  ]
})
export class CategoryModule { }
