import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-info',
  template: `
    <div class="mb-3">
      <div
        class="alert alert-success"
        role="alert"
        *ngIf="info">{{ info }}
      </div>
    </div>
  `

})
export class InfoComponent {
  @Input() info: String;
}
