import {Injectable} from '@angular/core';
import {Admin} from '../model/admin/admin';

const ADMIN_KEY = 'auth-admin';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() {
  }

  signOut(): void {
    window.sessionStorage.clear();
  }


  public saveAdmin(admin: Admin): void {
    window.sessionStorage.removeItem(ADMIN_KEY);
    window.sessionStorage.setItem(ADMIN_KEY, JSON.stringify(admin));
  }

  public getAdmin(): Admin {
    try {
      return JSON.parse(sessionStorage.getItem(ADMIN_KEY));
    } catch (e) {
      console.error('Unable to parse user');
      window.sessionStorage.removeItem(ADMIN_KEY);
      return null;
    }
  }

  public isExpired(): boolean {
    const expiration = this.getExpiration();
    return new Date(expiration) < new Date();
  }

  getExpiration(){
    try {
      return this.getAdmin().expiration;
    }
    catch (exp){
      return 0;
    }
  }


}
