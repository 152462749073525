<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-3 col-md-6 col-sm-8 align-self-center">
      <div class="box-part text-center">
        <h3>ADMIN LOGIN</h3>

        <form name="form" (ngSubmit)="f.form.valid && login()"
              #f="ngForm" novalidate>

          <img alt="user" width="120" height="120"
               class="profile-img-card mb-4"
               id="profile-img"
               src="{{imgLoginPath}}">

          <div class="input-group input-group-lg mb-4">
            <span class="input-group-text"><fa-icon class="login-icon" [icon]="faUser"></fa-icon></span>
            <input
              placeholder="username"
              type="text"
              class="form-control"
              name="username"
              [(ngModel)]="form.username"
              required
              #username="ngModel"
            />
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && username.invalid"
          >
            Username is required!
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="large"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="input-group input-group-lg mb-4">
            <span class="input-group-text"><fa-icon class="login-icon" [icon]="faLock"></fa-icon></span>

            <input
              placeholder="password"
              type="password"
              class="form-control"
              name="password"
              [(ngModel)]="form.password"
              required
              minlength="4"
              #password="ngModel"
            />
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && password.invalid">
            <div *ngIf="password.errors.required">Password is required</div>
            <div *ngIf="password.errors.minlength">Password must be at least 4 characters</div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12">
            <button class="btn btn-primary btn-lg w-100" type="submit">Sign in</button>
            </div>
          </div>

          <div class="row">
              <a class="mt-3" routerLinkActive="active"
                 [routerLink]="['/forgot-password']">Forgotten&nbsp;password</a>
          </div>

          <app-error [errorDto]="errorDto"></app-error>
        </form>
      </div>
    </div>
  </div>
</div>
