/**
 * Eshop platform core
 * Walderkova a Dejvova servica
 *
 * OpenAPI spec version: 1.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericConfigKeyDto } from './generic-config-key-dto';

export interface GenericConfigDataDto {
    key?: GenericConfigKeyDto;
    type?: GenericConfigDataDto.ConfigTypeEnum;
    value?: string;
}
export namespace GenericConfigDataDto {
    export type ConfigTypeEnum = 'SHORT_STRING' | 'LONG_TEXT' | 'NUMBER';
    export const ConfigTypeEnum = {
        SHORTSTRING: 'SHORT_STRING' as ConfigTypeEnum,
        LONGTEXT: 'LONG_TEXT' as ConfigTypeEnum,
        NUMBER: 'NUMBER' as ConfigTypeEnum
    };
}
