import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedImgService {
  private subjectLoginImgStream = new BehaviorSubject<string>('/assets/images/user-white.png');
  currentImgPath = this.subjectLoginImgStream.asObservable();

  public addLoginImgPath(path: string) {
    this.subjectLoginImgStream.next(path);
  }

  constructor() {}
}
