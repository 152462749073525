import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GenericConfigEditComponent} from "./generic-config-edit/generic-config-edit.component";
import {GenericConfigCreateComponent} from "./generic-config-create/generic-config-create.component";
import {GenericConfigEditDetailComponent} from "./generic-config-edit-detail/generic-config-edit-detail.component";
import {GenericConfigListFilterComponent} from "./generic-config-list-filter/generic-config-list-filter.component";
import {GenericConfigListComponent} from "./generic-config-list/generic-config-list.component";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {RouterLink} from "@angular/router";
import {ImgConfigurationComponent} from "./img-configuration/img-configuration.component";
import {BankConfigurationComponent} from "./bank-configuration/bank-configuration.component";
import {AdvertisementViewComponent} from "./advertisement-view/advertisement-view.component";


@NgModule({
  declarations: [
    GenericConfigListComponent,
    GenericConfigListFilterComponent,
    GenericConfigEditDetailComponent,
    GenericConfigCreateComponent,
    GenericConfigEditComponent,
    ImgConfigurationComponent,
    AdvertisementViewComponent,
    BankConfigurationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxSpinnerModule,
    FaIconComponent,
    RouterLink
  ]
})
export class GenericConfigModule {
}
