import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

import {Subject} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import {Admin} from "../../model/admin/admin";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {AccountService} from "../../service/account.service";
import {AuthService} from "../../service/auth.service";
import {Role} from "../../model/admin/role";

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html'
})
export class AccountListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  adminList: Array<Admin> = new Array<Admin>();
  faEdit = AppConstants.iconEdit;
  faDelete = AppConstants.iconDelete;
  currentAdmin: Admin;
  errorDto: ErrorDto;
  info: String;

  constructor(private accountService: AccountService,
              private authService: AuthService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }


  ngOnInit(): void {
    this.errorDto = new ErrorDto();
    this.currentAdmin = this.authService.currentAdminValue;
    this.getAccounts();
  }

  private getAccounts() {
    this.spinner.show('accountSpinner').then(r => r);
    this.accountService.getAccounts(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: adminList => {
          this.spinner.hide('accountSpinner').then(
            () => this.toastr.info(adminList.data.length + ' accounts reloaded', 'Accounts'));
          this.adminList = adminList.data;
        },
        error: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' load accounts failed', 'Accounts');
              this.adminList = new Array<Admin>();
            });
        }
      });
  }

  public deleteAccount(username: String) {
    if (!confirm('Are you sure to delete account?' + username + '?')) {
      return;
    }
    this.spinner.show().then(r => r);
    this.accountService.deleteAccount(username, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.info = 'Account ' + username + ' deleted';
              this.errorDto = new ErrorDto();
              this.toastr.success(' 204 account ' + username + ' deleted', 'Account');
              setTimeout(() => {
                this.info = null;
              }, AppConstants.toastSuccessDelay);
              this.getAccounts();
            }
          );
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(+this.errorDto.httpStatus + ' delete failed', 'Account')
          );
        }
      });
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showDelete() {
    return this.currentAdmin?.role?.toString() == Role[Role.OWNER];
  }
}
