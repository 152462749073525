import {Component, OnDestroy, OnInit} from '@angular/core';

import {takeUntil} from 'rxjs/operators';

import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Category} from "../../model/category/category";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {CategoryService} from "../../service/category.service";


@Component({
  selector: 'app-category-edited',
  templateUrl: './category-upsert.component.html'
})
export class CategoryUpsertComponent implements OnInit, OnDestroy {
  selectedOriginalCategoryUrl: string;
  selectedCategory = new Category();
  faArrowLeft = AppConstants.iconArrowLeft;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto = new ErrorDto();
  operation: string = "Create";

  constructor(private categoryService: CategoryService,
              private route: Router,
              private actRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe(params => {
      this.selectedOriginalCategoryUrl = params.get('categoryUrl');
      if(!this.selectedOriginalCategoryUrl){
        this.operation = "Create";
      }
      else{
        this.operation = "Update";
      }
      this.loadCategory();
    });
  }

  public loadCategory(){
    if (this.operation == "Update"){
      this.getCategory();
    }
  }
  public getCategory(){
    this.spinner.show().then(r => r);
    this.categoryService.getCategory(this.selectedOriginalCategoryUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categories => {
          this.spinner.hide().then(r => r);
          this.selectedCategory = categories.data;
          this.operation = "Update";
        },
        error: () => {
          this.spinner.hide().then( r => {
              this.operation = "Create";
              this.toastr.error(this.errorDto.httpStatus + ' load categories failed', 'Category')
            }
          );
        }
      });
  }

  public upsertCategory(){
    if(this.operation == "Create"){
      this.createCategory()
    }
    else {
      this.updateCategory();
    }
  }
  private updateCategory() {
    this.spinner.show().then(r => r);
    this.categoryService.updateCategory(this.selectedCategory, this.selectedOriginalCategoryUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.success(' Category updated', 'Account');
              setTimeout(() => {
                this.route.navigate(['/categories/view/',this.selectedCategory.url]).then(r => r);
              }, AppConstants.toastSuccessDelay);
            }
          );
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error( this.errorDto.httpStatus + ' update failed', 'Category'));
        }
      });
  }

  private createCategory() {
    this.spinner.show().then(r => r);
    this.categoryService.createCategory(this.selectedCategory, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.success('Category ' + this.selectedCategory.url + ' inserted', 'Category');
              setTimeout(() => {
                this.route.navigate(['/categories/view/',this.selectedCategory.url]).then(r => r);
              }, AppConstants.toastSuccessDelay);
            }
          );
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' Category failed!', 'Category'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
