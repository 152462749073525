<div class = "box">
<div class="row justify-content-center">
  <div class="col-lg-4 col-sm-8 align-self-center">
        <div class="box-part text-center">
          <form #upsertCategoryForm="ngForm" novalidate (ngSubmit)="upsertCategoryForm.valid && upsertCategory()">
            <h2>{{operation}} category</h2>

            <div class="mb-3">
              <label for="category-edit-name" class="form-label">Name</label>
              <input id="category-edit-name" name="catName" class="form-control" type="text"
                     placeholder="required"
                     required minlength="4"
                     [(ngModel)]="selectedCategory.name" #catName="ngModel">

              <div *ngIf="upsertCategoryForm.submitted && catName.invalid" class="alert alert-danger">
                <div *ngIf="catName.errors.required">Name is required.</div>
                <div *ngIf="catName.errors.minlength">  Name must be at least 4 characters long.</div>
              </div>
            </div>

            <ngx-spinner  bdColor="rgba(51,51,51,0.8)"
                          size="medium"
                          color="#fff"
                          type="ball-spin-clockwise">
            </ngx-spinner>

            <div class="mb-3">
              <label for="category-edit-url" class="form-label">Url</label>
              <input id="category-edit-url" name="url" class="form-control" type="text"
                     placeholder="required"
                     required appUrlValidator
                     [(ngModel)]="selectedCategory.url" #url="ngModel">
              <div *ngIf="upsertCategoryForm.submitted && url.invalid && (url.dirty || url.touched)"
                   class="alert alert-danger">
                <div *ngIf="url.errors.required">Url is required.</div>
                <div *ngIf="url.errors.isInvalidUrl && !url.errors.required"> Url must be valid</div>
              </div>
            </div>

            <div class="mb-3">
              <label for="category-edit-description" class="form-label">Description</label>
              <textarea id="category-edit-description" name="description"
                        class="form-control"
                        maxlength="60" minlength="12"
                        [(ngModel)]="selectedCategory.description"
                        #description="ngModel"></textarea>
              <div
                *ngIf="upsertCategoryForm.submitted && description.invalid && (description.dirty || description.touched)"
                class="alert alert-danger">
                <div *ngIf="description.errors.minlength">
                  Description must be at least 12 characters long.
                </div>
                <div *ngIf="description.errors.minlength">
                  Description must be maximum least 60 characters long.
                </div>
              </div>
            </div>

            <app-error [errorDto]="errorDto"></app-error>

            <div class="col-auto mb-3">
              <button type="submit" class="btn btn-primary mx-3 mx-sm-5">{{operation}}</button>
              <button type="button" class="btn btn-danger mx-3 mx-sm-5" appBackButton>
                <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
