import {Injectable} from '@angular/core';
import {BaseService} from "../common/abstract/base-service";
import {HttpClient} from "@angular/common/http";
import {AppConstants} from "../common/app-constants";
import {ErrorDto} from "../model/error/error-dto";
import {catchError, retry} from "rxjs/operators";
import {OrderPie} from "../model/stats/order-pie";
import {StatsCategoryToProductLineCountDto} from "../model/stats/stats-category-to-product-line-count-dto";
import {StatsOrderDayToSoldDto} from "../model/stats/stats-order-day-to-sold-dto";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends BaseService{

  private orderPieEndpoint = AppConstants.baseUrl + '/api/statistics/orders/pie/status';
  private categoryLineEndpoint = AppConstants.baseUrl + '/api/statistics/category/line/count';
  private orderTotalPerDayEndpoint = AppConstants.baseUrl + '/api/statistics/orders/line/sold';
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getOrderPieStatus(errorDto: ErrorDto) {
    return this.httpClient.get<Array<OrderPie>>(this.orderPieEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        retry(1),
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getCategoryToProductCount(errorDto: ErrorDto) {
    return this.httpClient.get<Array<StatsCategoryToProductLineCountDto>>(this.categoryLineEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        retry(1),
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getOrderTotalPerDay(errorDto: ErrorDto) {
    return this.httpClient.get<Array<StatsOrderDayToSoldDto>>(this.orderTotalPerDayEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        retry(1),
        catchError((err) => this.handleError(err, errorDto))
      );
  }
}
