import {Injectable} from '@angular/core';
import {BaseService} from "../common/abstract/base-service";
import {AppConstants} from "../common/app-constants";
import {HttpClient} from "@angular/common/http";
import {ErrorDto} from "../model/error/error-dto";
import {VariantCategoryWrapper} from "../model/variant-category/variant-category-wrapper";
import {catchError} from "rxjs/operators";
import {ProductSkuRequest} from "../model/product-sku/product-sku-request";
import {ProductSku} from "../model/product-sku/product-sku";
import {ProductSkuList} from "../model/product-sku/product-sku-list";
import {ProductSkuResponse} from "../model/product-sku/product-sku-response";
import {ProductImageData} from "../model/product/image/product-image-data";

@Injectable({
  providedIn: 'root'
})
export class ProductSkuService extends BaseService {

  private productSkuRootUrl = AppConstants.baseUrl + '/api/products/skus/';
  private productImageEndpoint =  this.productSkuRootUrl + 'img-upload/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public upsertProductSkuImage(file: File, productSkuUrl: string, errorDto: ErrorDto) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.put<ProductImageData>(this.productImageEndpoint + productSkuUrl, formData)
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
  public createProductSku(rootUrl: string, productSkuu: ProductSku, errorDto: ErrorDto) {
    const body = JSON.stringify(new ProductSkuRequest(productSkuu));
    return this.httpClient.post<VariantCategoryWrapper>(this.productSkuRootUrl + rootUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public getProductSku(sku: string, errorDto: ErrorDto) {
    return this.httpClient.get<ProductSkuResponse>(this.productSkuRootUrl + sku, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public deleteProductSku(sku: string, errorDto: ErrorDto) {
    return this.httpClient.delete(this.productSkuRootUrl + sku, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public skuList(rootUrl: string, errorDto: ErrorDto) {
    return this.httpClient.get<ProductSkuList>(this.productSkuRootUrl + rootUrl.concat("/by-parent-product"), {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }
}

