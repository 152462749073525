<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-sm-10 align-self-center">
      <div class="box-part text-center">
        <h3>Categories</h3>

        <div class="row text-center">
          <div class="input-group d-none d-sm-inline-flex">
            <div class="col-12 col-sm-3">
              <span class="text-darkorange">Name</span>
            </div>
            <div class="col-12 col-sm-3">
              <span class="text-darkorange">Url</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="text-darkorange">Description</span>
            </div>
            <div class="col-12 col-sm-1">
              <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
            </div>
            <div class="col-12 col-sm-1">
              <fa-icon class="text-darkorange" [icon]="faTrash"></fa-icon>
            </div>
          </div>
        </div>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <div class="row link text-center text-md-start" *ngFor="let category of categories">
          <div class="input-group border-bottom py-2">
            <div class="col-12 col-sm-3">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="Name"></span>
              <span class="d-inline-block m-2 text-white pointer" routerLinkActive="active"
                    [routerLink]="['/categories/view/', category.url]"><fa-icon class="mx-2" [icon]="faEye"></fa-icon>
                {{category.name}}
              </span>
            </div>
            <div class="col-12 col-sm-3 text-white">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="Url"></span>
              <span class="d-inline-block m-2 text-white">{{category.url}}</span>
            </div>
            <div class="col-12 col-sm-4">
              <span class="d-inline-block m-2 text-orange d-sm-none" data-label="Description"></span>
              <span class="d-inline-block m-2 text-white overflow">{{category.description}}</span>
            </div>
            <div class="col-12 col-sm-1 my-auto">
              <button class="btn btn-info mt-1" routerLinkActive="active"
                      [routerLink]="['/categories/upsert']" [queryParams]=" { categoryUrl:category.url }">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>
            <div class="col-12 col-sm-1 my-auto text-center">
              <button class="btn btn-danger mt-1" (click)="removeCategory(category);">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <app-error [errorDto]="errorDto"></app-error>
        <button class="btn btn-primary mt-4 text-center" routerLinkActive="active" routerLink="/categories/upsert">Add
          category
        </button>
      </div>
    </div>
  </div>
</div>
