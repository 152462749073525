import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';

import {Subject} from "rxjs";

import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {BaseImageComponent} from "../../common/abstract/base-image-component";
import {Product} from "../../model/product/product";
import {ProductSku} from "../../model/product-sku/product-sku";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {VariantCatalog} from "../../model/variant-category/variant-catalog";
import {ProductSkuVariant} from "../../model/product-sku/product-sku-variant";
import {ProductService} from "../../service/product.service";
import {GenericConfigService} from "../../service/generic-config.service";
import {ProductSkuService} from "../../service/product-sku.service";
import {VariantCatalogService} from "../../service/variant-catalog.service";
import {takeUntil} from "rxjs/operators";
import {VariantDetail} from "../../model/variant-category/variant-detail";


@Component({
  selector: 'app-product-sku',
  templateUrl: './product-sku.component.html'
})
export class ProductSkuComponent extends BaseImageComponent implements OnDestroy, OnChanges {
  @Input() selectedProductRootUrl: string;
  @Input() rootProduct = new Product();
  productSku = new ProductSku();
  faArrowLeft = AppConstants.iconArrowLeft;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto = new ErrorDto();
  selectedCatalog: VariantCatalog;
  variantCatalogs: VariantCatalog[];
  addedVariants: ProductSkuVariant[] = [];
  productSkus: ProductSku[] = []
  faDelete: any;

  constructor(protected productService: ProductService,
              protected genericConfig: GenericConfigService,
              protected productSkuService: ProductSkuService,
              protected variantCatalog: VariantCatalogService,
              protected route: Router,
              protected actRoute: ActivatedRoute,
              protected spinner: NgxSpinnerService,
              protected toastr: ToastrService) {
    super(spinner, toastr, route, productService, genericConfig, true);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.rootProduct = changes.rootProduct.currentValue;
    this.productSku.priceAmount = this.rootProduct.priceAmount;
    this.productSku.name = this.rootProduct.name;
    this.productSku.sku = this.rootProduct.url;
    this.getCatalogs();
  }
  private getCatalogs() {
    this.spinner.show('catalogSpinner').then(r => r);
    return this.variantCatalog.listAll(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide('catalogSpinner').then(
            () => {
              this.variantCatalogs = response.data;
              this.selectedCatalog = this.variantCatalogs[0];
              this.errorDto = new ErrorDto();
            });
        },
        error: () => {
          this.spinner.hide('catalogSpinner').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' load catalog failed!', 'Product');
            });
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  selectCatalog($event: VariantCatalog) {
    this.selectedCatalog = $event;
  }
  addVariant(variant: VariantDetail, catalogName: VariantCatalog) {
    const val = new ProductSkuVariant(variant?.name, catalogName?.paramName);
    const filtered = this.addedVariants.filter(data => data.variantCatalog === catalogName.paramName);
    if (filtered.length == 0) {
      this.addedVariants.push(val);
    }
  }
  removeVariant(index: number) {
    this.addedVariants = this.addedVariants.filter((ele, ind) => ind !== index);
  }
  insertSku() {
    this.productSku.variants = this.addedVariants;
    this.productSku.priceCurrency = "CZK";
    this.productSkus.push(Object.assign({}, this.productSku));
    this.addedVariants = [];
  }
  removeSku(index: number) {
    this.productSkus = this.productSkus.filter((ele, int) => int !== index);
  }
  resolveVariants(productSku: ProductSku) {
    const val = productSku.variants?.length > 0
      ? " [ " + productSku.variants.map(data => data.variantName).join(", ") + " ] "
      : "";
    return productSku.sku.concat(val);
  }
  saveProductVariants() {
    this.productSkus.forEach(data => this.createProductSku(data));
    setTimeout(() => {
      if (this.productSkus.length == 0) {
        this.route.navigate(['/products/view/', this.selectedProductRootUrl]).then(r => r);
      }
    }, AppConstants.toastSuccessDelay)
  }
  createProductSku(productSku: ProductSku) {
    this.spinner.show('productSkuSpinner').then(r => r);
    this.productSkuService.createProductSku(this.selectedProductRootUrl, productSku, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => {
              this.productSkus = this.productSkus.filter((ele) => ele !== ele);
              this.toastr.success('Sku' + productSku + ' added ', 'Product');
            });
        },
        error: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' add sku failed!', 'Product');
            });
        }
      });
  }
}
