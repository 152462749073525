import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {AppConstants} from "../../common/app-constants";

@Component({
  selector: 'app-img-view',
  styleUrls: ['./img-update.component.css'],
  template: `
    <div class="row justify-content-center content">
      <div class="col-xs-12 col-md-8">
        <img *ngIf="existingImage.getValue()" class="img-fluid mb-2" [src]="existingImage.getValue()" alt="{{imgName}}">
        <img *ngIf="!existingImage.getValue()" class="img-fluid show-image mb-2" [src]="emptyDefaultImage" alt="empty image">
        <div class="info">
          <span *ngIf="imgContentType">{{imgContentType}}</span><br>
          <details *ngIf="height">
            <summary>{{height}} x {{width}}</summary>
            <code>height x width</code>
          </details>
        </div>
      </div>
    </div>
  `,
})
export class ImgViewComponent implements OnInit{
  emptyDefaultImage = AppConstants.emptyDefaultImage;
  width: number;
  height: number;
  @Input() existingImage: BehaviorSubject<string>;
  @Input() imgName: string;
  @Input() imgContentType: string;

  ngOnInit(): void {
    this.existingImage.subscribe(data =>{
      const img = new Image();
      img.src = data;
      img.onload = () => {
        this.width = img.width;
        this.height = img.height
      }
    })

  }

}
