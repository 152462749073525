import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorDto} from '../model/error/error-dto';
import {AppConstants} from '../common/app-constants';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../service/account.service';
import {takeUntil} from 'rxjs/operators';
import {SharedImgService} from "../service/shared-img.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './password-change.component.html'
})
export class PasswordChangeComponent implements OnInit, OnDestroy {

  form: any = {};
  errorDto: ErrorDto = new ErrorDto();
  faUser = AppConstants.iconUser;
  faLock = AppConstants.iconLock;
  token = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  header = 'Create password';
  imgPath: string;

  constructor(
    private accountService: AccountService,
    private sharedImgService: SharedImgService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.sharedImgService.currentImgPath.subscribe(data => this.imgPath = data);
    this.activatedRoute.queryParamMap
      .subscribe(params => {
        this.token = params.get('token') || '';
      });
  }

  passwordChange() {
    this.spinner.show('refreshPasswordSpinner').then(r => r);
    this.accountService.passwordChange(this.form, this.token, this.errorDto,)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.success('Account activated', 'Activation');
              this.route.navigate(['/login']).then(r => r);
            }
          );

        },
        error: () => {
          this.toastr.error(this.errorDto.httpStatus + ' password change failed', 'Login');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
