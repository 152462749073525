export class AddressDetailBaseDto {
    firstName?: string;
    lastName?: string;
    street?: string;
    streetNumber?: string;
    city?: string;
    zipCode?: string;
    country?: string;
    note?: string;
    phone?: string;
}

