import {Component, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';

import {NgxSpinnerService} from 'ngx-spinner';

import {ToastrService} from 'ngx-toastr';
import {Product} from "../../model/product/product";
import {Audit} from "../../model/audit/audit";
import {AppConstants} from "../../common/app-constants";
import {ProductSku} from "../../model/product-sku/product-sku";
import {Category} from "../../model/category/category";
import {ErrorDto} from "../../model/error/error-dto";
import {ProductService} from "../../service/product.service";
import {ProductSkuService} from "../../service/product-sku.service";


@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html'
})
export class ProductViewComponent implements OnInit, OnDestroy {

  selectedProductOriginalUrl: string;
  selectedProduct: Product = new Product();
  selectedAudit: Audit = new Audit();
  selectedCategoryUrl: string;
  faEdit = AppConstants.iconEdit;
  faArrowLeft = AppConstants.iconArrowLeft;
  faPlus = AppConstants.iconPlus;
  faThrash = AppConstants.iconTrash;
  faUser = AppConstants.iconUser;
  faEye = AppConstants.iconEye;
  faTrash = AppConstants.iconTrash;
  productSkus: ProductSku[];
  categories: Category[];
  existingImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imgContentType: string;
  errorDto = new ErrorDto();
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorProductSkuDto = new ErrorDto();

  constructor(protected productService: ProductService,
              protected productSkuService: ProductSkuService,
              protected route: Router,
              protected actRoute: ActivatedRoute,
              protected spinner: NgxSpinnerService,
              protected toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.selectedProductOriginalUrl = params.get('productUrl');
      this.getProduct();
      this.getImage();
      this.loadSkus();
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private loadSkus() {
    this.spinner.show('productSkuSpinner').then(r => r);
    return this.productSkuService.skuList(this.selectedProductOriginalUrl, this.errorProductSkuDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: skuResponse => {
          this.spinner.hide('productSkuSpinner').then(
            () => {
              this.productSkus = skuResponse.data;
              this.errorProductSkuDto = new ErrorDto();
            });
        },
        error: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => this.toastr.error(this.errorProductSkuDto.httpStatus + ' product skus failed', 'Product')
          );
        }
      });
  }

  private getProduct() {
    this.spinner.show('productSpinner').then(r => r);
    return this.productService.getProduct(this.selectedProductOriginalUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productResponse => {
          this.spinner.hide('productSpinner').then(
            () => {
              this.selectedProduct = productResponse.data;
              this.selectedAudit = productResponse.audit;
              this.selectedCategoryUrl = this.selectedProduct.categoryUrls[0];
            });
        },
        error: () => {
          this.spinner.hide('productSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' product reload failed', 'Product')
          );
        }
      });
  }

  getImage() {
    this.spinner.show('productImageSpinner').then(r => r);
    this.productService.getProductImage(this.selectedProductOriginalUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productImageData => {
          this.spinner.hide('productImageSpinner').then(() => {
            if (productImageData.data.bytes == null) {
              this.imgContentType = null;
              this.toastr.warning(this.selectedProductOriginalUrl + ' has empty image', 'Product');
            } else {
              this.existingImage.next('data:image/jpeg;base64,' + productImageData.data.bytes);
              this.imgContentType = productImageData.data.type;
              this.toastr.info(this.selectedProductOriginalUrl + ' image reloaded', 'Product');
            }
          });
        },
        error: () => {
          this.spinner.hide('productImageSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' image reloaded fail', 'Product'));
        }
      });
  }

  removeProductSku(productSku: ProductSku) {
    if (!confirm('Are you sure to delete sku?' + productSku.sku + '?')) {
      return;
    }
    this.spinner.show('productSkuSpinner').then(r => r);
    return this.productSkuService.deleteProductSku(productSku.sku, this.errorProductSkuDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => {
              this.errorProductSkuDto = new ErrorDto();
              this.loadSkus();
            });
        },
        error: () => {
          this.spinner.hide('productSkuSpinner').then(
            () => this.toastr.error(this.errorProductSkuDto.httpStatus + ' product skus failed', 'Product')
          );
        }
      });
  }

}
