/**
 * Eshop platform core
 * Walderkova a Dejvova servica
 *
 * OpenAPI spec version: 1.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericConfigDataDto } from './generic-config-data-dto';

export class GenericConfigCreateDto {
    data?: GenericConfigDataDto;

  constructor(data: GenericConfigDataDto) {
    this.data = data;
  }
}
