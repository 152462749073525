export class ProductSkuVariant {

  constructor(variantName: string, variantCatalog: string) {
    this.variantName = variantName;
    this.variantCatalog = variantCatalog;
  }

  variantName: string;
  variantCatalog: string;
}
