import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {ProductSkuComponent} from "./product-sku/product-sku.component";
import {ProductListComponent} from "./product-list/product-list.component";
import {ProductUpsertComponent} from "./product-upsert/product-upsert.component";
import {ProductViewComponent} from "./product-view/product-view.component";
import {FormsModule} from "@angular/forms";
import {NgxSpinnerModule} from "ngx-spinner";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {RouterLink} from "@angular/router";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ImgUpdateComponent} from "./img/img-update.component";
import {ImgViewComponent} from "./img/img-view.component";
import {ProductSkuViewComponent} from "./sku-view/product-sku-view.component";


@NgModule({
  declarations: [
    ProductViewComponent,
    ProductUpsertComponent,
    ProductListComponent,
    ProductSkuComponent,
    ProductSkuViewComponent,
    ImgUpdateComponent,
    ImgViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxSpinnerModule,
    FaIconComponent,
    RouterLink,
    AngularEditorModule
  ]
})
export class ProductModule { }
