import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Admin} from "../../model/admin/admin";
import {ErrorDto} from "../../model/error/error-dto";
import {AppConstants} from "../../common/app-constants";
import {Role} from "../../model/admin/role";
import {AccountService} from "../../service/account.service";
import {AuthService} from "../../service/auth.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html'
})
export class AccountCreateComponent implements OnInit, OnDestroy {
  account: Admin = new Admin();
  errorDto: ErrorDto;
  faArrowLeft = AppConstants.iconArrowLeft;
  destroy$: Subject<boolean> = new Subject<boolean>();
  info = '';
  roles = Object.keys(Role)
    .map(key => Role[key])
    .filter(value => typeof value === 'string' && value != Role[Role.OWNER]) as string[];
  faUser = AppConstants.iconUser;
  roleHelper = Role;
  faCalendar = AppConstants.iconCalendar;

  constructor(private accountService: AccountService,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private route: Router,) {

  }

  ngOnInit(): void {
    this.account = new Admin();
    this.account.role = Role.ADMIN;
    this.errorDto = new ErrorDto();
  }


  selectRole(value:Role) {
    this.account.role = value;
  }

  createAccount() {
    this.spinner.show('accountSpinner').then(r => r);
    this.accountService.createAccount(this.account, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: adminResponse => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.success('Account updated 200', 'Accounts');
              this.account = adminResponse.data;
              this.errorDto = new ErrorDto();
              this.info = 'Account created';
              setTimeout(() => {
                this.route.navigate(['/accounts']);
              }, AppConstants.toastSuccessDelay);
            }
          );
        },
        error: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.error('Account create failed ' + this.errorDto.httpStatus, 'Accounts');
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
