import {ProductSkuVariant} from "./product-sku-variant";
import {ProductImage} from "../product/image/product-image";

export class ProductSku {
  name: string;
  sku: string;
  priceAmount: number;
  priceCurrency: string;
  file: ProductImage;
  variants: ProductSkuVariant[];
}
