import {Directive, Input} from '@angular/core';
import {FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appMatchPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordMatcherDirective, multi: true}]
})
export class PasswordMatcherDirective implements Validator {
  @Input('appMatchPassword') matchPassword: string[] = [];

  constructor() {
  }

  validate(formGroup: FormGroup): ValidationErrors | null {
    return this.match(this.matchPassword[0], this.matchPassword[1])(formGroup);
  }

  match(controlName: string, checkControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const checkControl = formGroup.controls[checkControlName];

      if (control?.value == null && checkControl?.value == null) {
        checkControl?.setErrors(null);
        return null;
      }
      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }
      if (control?.value !== checkControl?.value) {
        checkControl?.setErrors({matching: true});
        console.log('errors set tu true');
        return {matching: true};
      } else {
        console.log('errors set tu false');
        checkControl?.setErrors(null);
        return null;
      }
    };
  }

}
