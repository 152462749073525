<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-6 align-self-center">
      <div class="box-part text-center">
        <form #editAccountForm="ngForm" novalidate (ngSubmit)="
         (select.valid || select.isDisabled)
         && (email.valid || email.isDisabled)
         && (username.valid || username.isDisabled)
         && updateAccount()">
          <h2>Update account</h2>

          <app-audit [audit]="audit"></app-audit>

          <div class="mb-1">
            <label for="account-username" class="col-form-label">Username</label>
            <input id="account-username" name="username" class="form-control" type="text"
                   placeholder="required"
                   required minlength="4"
                   [(ngModel)]="selectedAccount.username" #username="ngModel"
                   [disabled]="isNotOwnAccount()">

            <div *ngIf="editAccountForm.submitted && username.invalid" class="alert alert-danger">
              <div *ngIf="username.errors.required">Name is required.</div>
              <div *ngIf="username.errors.minlength"> Name must be at least 4 characters long.</div>
            </div>
          </div>

          <div class="mb-1">
            <label for="account-email" class="col-form-label">Email</label>
            <input id="account-email" name="email" class="form-control" type="text"
                   placeholder="required"
                   required minlength="4"
                   [(ngModel)]="selectedAccount.email" #email="ngModel"
                   [disabled]="isNotOwnAccount()">

            <div *ngIf="editAccountForm.submitted && email.invalid" class="alert alert-danger">
              <div *ngIf="email.errors.required">Email is required.</div>
            </div>
          </div>

          <div class="mb-1">
            <label for="account-role" class="col-form-label">Role</label>
            <select class="form-select mb-3"
                    [(ngModel)]="selectedAccount.role"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="selectRole($event)"
                    #select="ngModel"
                    id="account-role"
                    minlength="4"
                    [disabled]="isOwnAccountOrOwnerRole()"
            >
              <option *ngFor="let role of roles"
              >
                {{role}}
              </option>
            </select>
          </div>

          <div *ngIf="editAccountForm.submitted && select.invalid"
               class="alert alert-danger">
            <div *ngIf="select.errors.required">
              Role must be selected.
            </div>
            <div *ngIf="select.errors.minlength">
              Role must be at least 4 characters long.
            </div>
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>


          <app-info [info]="info"></app-info>

          <app-error [errorDto]="errorDto"></app-error>
          <div class="col-auto">
            <button type="submit"[disabled]="!(editAccountForm.dirty || select.dirty)"
                    class="ms-3 ms-sm-5 btn btn-primary">Submit</button>
            <button type="button" class="ms-3 ms-sm-5 btn btn-danger" appBackButton>
              <fa-icon [icon]="faArrowLeft" class="me-2"></fa-icon>Back</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
