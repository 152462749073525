import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../common/app-constants';
import {catchError, map} from 'rxjs/operators';
import {TokenStorageService} from './token-storage.service';
import {Admin} from '../model/admin/admin';
import {ErrorDto} from '../model/error/error-dto';
import {BaseService} from '../common/abstract/base-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService{

  private currentAdminSubject: BehaviorSubject<Admin>;
  public currentAdmin: Observable<Admin>;

  constructor(private http: HttpClient,
              private tokenStorage: TokenStorageService) {
    super();
    const admin = tokenStorage.isExpired() ? null : tokenStorage.getAdmin();
    this.currentAdminSubject = new BehaviorSubject<Admin>(admin);
    this.currentAdmin = this.currentAdminSubject.asObservable();
    if (!admin) {
      this.logout();
    }
  }

  public get currentAdminValue(): Admin {
    return this.currentAdminSubject.value;
  }

  login(credentials, errorDto:ErrorDto) {
    return this.http.post<Admin>(AppConstants.baseUrl + '/authenticate', {
      username: credentials.username,
      password: credentials.password
    }, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(map(resp => {
        console.log(resp);
        this.tokenStorage.saveAdmin(resp);
        this.currentAdminSubject.next(resp);
        return resp;
      }), catchError((err) => this.handleError(err, errorDto)));
  }

  logout() {
    this.tokenStorage.signOut();
    this.currentAdminSubject.next(null);
  }
}
