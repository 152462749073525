import {GenericConfigDataDto} from "../generic-config/generic-config-data-dto";

export abstract class Config {
  static findValueByKey(name: string, data: GenericConfigDataDto[]): string {
    if (data == null) {
      return null;
    }
    return Config.findConfigByKey(name, data)?.value;
  }

  static findConfigByKey(name: string, data: GenericConfigDataDto[]): GenericConfigDataDto {
    if (data == null) {
      return null;
    }
    return data.find(key => key.key.name == name)
  }

  /*
    protected updateConfigByKey(value: string, name: string) {
      let configData = data.find(key => key.key.name == name);
      if (configData !== null) {
        configData.value = value;
      }
    }*/

}
