import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ErrorDto} from '../model/error/error-dto';
import {QrRequest} from '../model/qr/QrRequest';
import {QrResponse} from '../model/qr/QrResponse';
import {QrService} from '../service/qr.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {AppConstants} from "../common/app-constants";

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html'
})
export class QrComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  existingImage: any = null;
  errorDto = new ErrorDto();
  qrCodeRequest = new QrRequest();
  qrCodeResponse = new QrResponse();
  info = '';
  faArrowLeft = AppConstants.iconArrowLeft;

  constructor(private qrService: QrService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.qrCodeRequest.currency = 'CZK';
    this.qrCodeRequest.amount = 500;
    this.qrCodeRequest.iban = 'CZ1203000000000217963916';
    this.qrCodeRequest.variableSymbol = 444;
    this.qrCodeRequest.message = 'olala message';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadQr() {
    this.spinner.show().then(r => r);
    this.qrService.loadQr(this.qrCodeRequest, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: qrResponseData => {
          this.spinner.hide().then(() => {
            this.info = 'Oscanujte v mobilní aplikaci a ověřte, že QR code pro bankovní platby funguje.';
            this.qrCodeResponse = qrResponseData.data;
            this.existingImage = 'data:image/png;base64,' + this.qrCodeResponse.image;
            setTimeout(() => {
              this.info = '';
            }, 6000);
          });
        },
        error: () => {
          this.spinner.hide().then(() => {
            this.toastr.error(this.errorDto.httpStatus + ' loading failed', 'QrCode');
          });
        }
      });
  }
}
