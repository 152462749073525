<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-8 align-self-center">
      <div class="box-part text-center text-white">
        <h2>Sku Detail</h2>
        <hr>

        <span class="d-block">name: {{productSku.name}}</span>
        <span class="d-block">sku: {{productSku.sku}}</span>
        <span class="d-block" >price: {{productSku.priceAmount}} Kč</span>
        <app-img-view
          [imgName]="productSku.name"
          [existingImage]="existingImage"
          [imgContentType]="imgContentType"
        ></app-img-view>
        <ngx-spinner name="productSkuSpinner"
                     bdColor="rgba(51,51,51,0.8)"
                     size="medium"
                     color="#fff"
                     type="ball-spin-clockwise">
        </ngx-spinner>

        <app-audit [audit]="audit"></app-audit>
        <app-error [errorDto]="errorDto"></app-error>

        <div *ngIf="productSku.variants?.length>0">
          <h5>Variant names - catalog</h5>
          <div class="row text-center text-sm-center" *ngFor="let variant of productSku.variants;">
            <div class="input-group link border-bottom">
              <div class="col-sm-10 col">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
                <span class="d-inline-block m-2 text-white">{{variant.variantName}} - </span>
                <span class="d-inline-block m-2 text-white">{{variant.variantCatalog}}</span>
              </div>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-danger mt-3" appBackButton>
          <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
        </button>

      </div>
    </div>
  </div>
</div>
