import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Category} from '../model/category/category';
import {CategoryResponse} from '../model/category/category-response';
import {CategoriesResponse} from '../model/pagination/categories-response';
import {AppConstants} from '../common/app-constants';
import {BaseService} from '../common/abstract/base-service';
import {CategoryRequest} from '../model/category/category-request';
import {ErrorDto} from '../model/error/error-dto';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  private categoryEndpoint = AppConstants.baseUrl + '/api/categories';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getCategories(errorDto: ErrorDto) {
    return this.httpClient.get<CategoriesResponse>(this.categoryEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        retry(1),
        catchError((err) => this.handleError(err, errorDto))
      );
  }

  public getCategory(categoryUrl: string, errorDto: ErrorDto) {
    const categoryEndpointUrl = this.addParamIntoUrl(this.categoryEndpoint, categoryUrl);
    return this.httpClient.get<CategoryResponse>(categoryEndpointUrl, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public createCategory(category: Category, errorDto: ErrorDto) {
    const body = JSON.stringify(new CategoryRequest(category));
    return this.httpClient.post<CategoryResponse>(this.categoryEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public updateCategory(category: Category, originalUrl: string, errorDto: ErrorDto) {
    const body = JSON.stringify(new CategoryRequest(category));
    const categoryUrl = this.addParamIntoUrl(this.categoryEndpoint, originalUrl);
    return this.httpClient.put<CategoryResponse>(categoryUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }

  public deleteCategory(category: Category, errorDto: ErrorDto) {
    const categoryUrl = this.addParamIntoUrl(this.categoryEndpoint, category.url);
    return this.httpClient.delete(categoryUrl, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }
}
