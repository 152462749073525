<div class="input-group mt-3 mb-3 w-50 text-center">
  <label for="module-input">Module</label>
  <select id="module-input" class="form-select form-select-md"
          [(ngModel)]="selectedModule"
            (ngModelChange)="fireSelectChangedEvent()">
    <option *ngFor="let module of availableModules" [ngValue]="module">
      {{module}}
    </option>
  </select>
</div>
