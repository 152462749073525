<div class="order-address col-4 content text-start">
  <h3>{{ type }} address</h3>

  <div class="row">
    <div class="col-3">Name:</div>
    <div class="col-3">{{ address.firstName }} {{ address.lastName }}</div>
  </div>
  <div class="row">
    <div class="col-3">Street</div>
    <div class="col-3">{{ address.street }} {{ address.streetNumber }}</div>
  </div>
  <div class="row">
    <div class="col-3">City</div>
    <div class="col-3">{{ address.zipCode }} {{ address.city }}</div>
  </div>
  <div class="row">
    <div class="col-3">Country</div>
    <div class="col-3">{{ address.country }}</div>
  </div>
  <div class="row">
    <div class="col-3">Note:</div>
    <div class="col-3">{{ address.note }}</div>
  </div>
  <div class="row">
    <div class="col-3">Phone:</div>
    <div class="col-3">{{ address.phone }}</div>
  </div>
</div>







