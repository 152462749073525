import {Injectable} from '@angular/core';
import {BaseService} from '../common/abstract/base-service';
import {AppConstants} from '../common/app-constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ErrorDto} from '../model/error/error-dto';
import {catchError} from 'rxjs/operators';
import {WriteUsListResponse} from '../model/email/write-us-list-response';
import {EmailRequest} from '../model/email/email-request';
import {Email} from '../model/email/email';

@Injectable({
  providedIn: 'root'
})
export class WriteUsService extends BaseService {

  private readonly writeUsEndpoint = AppConstants.baseUrl + '/api/email';
  private readonly answerEndpoint = AppConstants.baseUrl + '/api/email/respond';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public responseToEmail(errorDto: ErrorDto, email: Email) {
    return this.httpClient.post(this.answerEndpoint, JSON.stringify(new EmailRequest(email)),
      {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public loadEmailsWriteUs(errorDto: ErrorDto, pageNumber?: number, pageSize?: number, email?: string, replied?: boolean) {
    let paramsData = new HttpParams();

    if (pageNumber != null) {
      paramsData = paramsData.set('pageNumber', pageNumber);
    }
    if (pageSize != null) {
      paramsData = paramsData.set('pageSize', pageSize);
    }
    if (email != null) {
      paramsData = paramsData.set('email', email);
    }
    if (replied != null) {
      paramsData = paramsData.set('replied', replied);
    }

    return this.httpClient.get<WriteUsListResponse>(this.writeUsEndpoint, {
      headers: AppConstants.acceptJson,
      params: paramsData
    })
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

}
