<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler mx-3 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHome"
          aria-controls="navbarHome" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse mx-3" id="navbarHome">
    <a class="navbar-brand brand" href="#">
      <img class="img-banner" src="/assets/images/logo.png" alt="logo">Admin eshop</a>

    <ul class="navbar-nav me-auto">
      <li class="nav-item"><span class="nav-link"><span class=" me-2">{{darkTheme}}</span>
        <ng-toggle
          (valueChange)="switchTheme()"
          [value]="value"
          [color]="{
           unchecked: 'blue',
           checked: 'darkorange'
          }"
        ></ng-toggle>
      </span>
      </li>
      <li class="nav-item" *ngIf="!currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="login">Login
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/categories'">Categories
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/products'">Products
        </a>
      </li>
      <li class="nav-item dropdown" id="myDropdown" *ngIf="currentAdmin">
        <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Configuration </a>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item"
                 routerLinkActive="active"
                 [routerLink]="'/advertisement'">Advertisement</a></li>
          <li><a class="dropdown-item"
                 routerLinkActive="active"
                 [routerLink]="'/bank'">Bank</a></li>
          <li><a class="dropdown-item"
                 routerLinkActive="active"
                 [routerLink]="'/img'">Image</a></li>
          <li><a class="dropdown-item"
                 routerLinkActive="active"
                 [routerLink]="'/qr-code'">Qr Code</a></li>
          <li><a class="dropdown-item"
                 routerLinkActive="active"
                 [routerLink]="'/generic-configs'">Generic config</a>
          </li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/variant-catalogs'">Catalog
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/accounts'">Accounts
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/graph/home'">Graphs
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin">
        <a class="nav-link"
           routerLinkActive="active"
           [routerLink]="'/orders'">Orders
        </a>
      </li>
      <li class="nav-item" *ngIf="currentAdmin"><a class="nav-link" (click)="logout()">Logout</a></li>
    </ul>
    <ul class="navbar-nav ms-auto">
      <li class="nav-item" *ngIf="currentAdmin"
          [popper]="popper1Content"
          [popperApplyClass]="'popper-content'"
          [popperShowOnStart]="false"
          [popperTrigger]="'hover'"
          [popperHideOnClickOutside]="true"
          [popperHideOnScroll]="true"
          [popperPlacement]="'bottom'">
        <a class="nav-link">
          <fa-icon [icon]="faUser"></fa-icon>&nbsp;{{currentAdmin.username}}</a></li>
      <popper-content #popper1Content>
        <span class="mb-2 d-block"><fa-icon [icon]="faEmail"></fa-icon>&nbsp;&nbsp;{{currentAdmin?.email}}</span>
        <span class="d-block"><fa-icon [icon]="faUser"></fa-icon>&nbsp;&nbsp;{{currentAdmin?.role}}</span>

      </popper-content>
      <li class="nav-item" *ngIf="currentAdmin"
          [popperApplyClass]="'popper-content'"
          [popper]="'Time out'"
          [popperTrigger]="'hover'"
          [popperPlacement]="'bottom'"><a class="nav-link">
        <fa-icon [icon]="faClock"></fa-icon>&nbsp;{{currentAdmin.expiration | date:'HH:mm' }}</a></li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>
