import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {

  public get current(): string {
    return localStorage.getItem('theme') ?? 'dark';
  }

  public set current(value: string) {
    localStorage.setItem('theme', value);
    this.style.href = `/assets/themes/${value}.css`;
  }

  private readonly style: HTMLLinkElement;

  constructor() {
    this.style = document.createElement('link');
    this.style.rel = 'stylesheet';
    document.head.appendChild(this.style);

    if (localStorage.getItem('theme') !== undefined) {
      this.style.href = 'dark.css';
    }
  }
}
