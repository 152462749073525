import {VariantDetail} from "./variant-detail";

export class VariantsRequest {
  data: VariantDetail[];

  constructor(data: VariantDetail[]) {
    this.data = data;
  }

}
