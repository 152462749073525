<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-4 col-md-6 col-sm-8 align-self-center">
      <div class="box-part text-center">
        <h3>{{header}}</h3>

        <form name="form" #registeredForm="ngForm"
              (ngSubmit)="registeredForm.form.valid && passwordChange()"
              novalidate
              [appMatchPassword]="['password', 'confirmPassword']">

          <img alt="user" width="120" height="120"
               class="profile-img-card mb-4"
               id="profile-img"
               src="{{imgPath}}"/>

          <div class="input-group input-group-lg mb-4">
            <span class="input-group-text"><fa-icon [icon]="faLock"></fa-icon></span>
            <input
              placeholder="password"
              type="password"
              class="form-control"
              name="password"
              [(ngModel)]="form.password"
              #password="ngModel"
              appPasswordPattern required
            />
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="registeredForm.submitted && password.errors?.invalidPassword">
            Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
            letter and 1 number
          </div>

          <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="input-group input-group-lg mb-4">
            <span class="input-group-text"><fa-icon [icon]="faLock"></fa-icon></span>

            <input
              placeholder="confirmPassword"
              type="password"
              class="form-control"
              name="confirmPassword"
              [(ngModel)]="form.confirmPassword"
              #confirmPassword="ngModel"
              required
            />
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="confirmPassword.errors?.required">
            Confirm Password is required
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="confirmPassword.errors?.matching">
            Passwords does not match
          </div>

          <div class="row">
            <div class="col">
              <button class="btn btn-primary btn-block mb-3" type="submit">Reset</button>
            </div>
          </div>
          <app-error [errorDto]="errorDto"></app-error>
        </form>
      </div>
    </div>
  </div>
</div>
